import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import { Form } from 'react-bootstrap';
import { verifyOTP } from '../../utils/API';
import { useNavigate } from 'react-router-dom';
import useAnalytics from '../../hooks/useAnalytics';
import ReactGA from 'react-ga4';
import './style.scss';

function VerifyPhone() {
  const [otp, setOtp] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const navigate = useNavigate();
  const { eventTracker } = useAnalytics('Address Creation');

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: window.location.pathname,
      title: 'Verify phone',
    });
  }, []);

  useEffect(() => {
    const localPhone = localStorage.getItem('phone');
    setPhone(localPhone ?? '');
  }, []);

  async function submit() {
    try {
      eventTracker({ action: 'verify_phone_submit', label: 'clicked' });
      const isVerified = await verifyOTP(phone, otp);
      if (isVerified) {
        eventTracker({ action: 'verify_phone_submit', label: 'success' });
        navigate('/account-created');
      } else {
        eventTracker({ action: 'verify_phone_submit', label: 'failure' });
        alert('Wrong OTP');
      }
    } catch (err) {
      eventTracker({ action: 'verify_phone_submit', label: 'failure' });
      alert('Error verifying the otp');
    }
  }

  return (
    <div className="verify-account">
      <h1>Verify your phone number</h1>
      <Form>
        <Form.Group className="mb-3" controlId="otp">
          <Form.Label>
            We sent you a 5-diget code to <strong>{phone}</strong>{' '}
          </Form.Label>
          <Form.Control
            id="otp"
            type="decimal"
            placeholder="123456"
            onChange={(e) => setOtp(e.target.value)}
          />
        </Form.Group>
        <div className="resend-link">
          <a>I didn't receive a code</a>
        </div>
      </Form>

      <div className="d-grid bottom-container">
        <Button variant="primary" size="lg" onClick={submit}>
          Continue
        </Button>
      </div>
    </div>
  );
}

export default VerifyPhone;
