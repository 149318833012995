import React, { useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import CountrySelector from '../../components/CountrySelector';
import {
  supportedCountries,
  ICountry,
} from '../../components/CountrySelector/countries';
import { signUp } from '../../utils/API';
import { useNavigate } from 'react-router-dom';
import './style.scss';
// @ts-ignore
import LoadingOverlay from 'react-loading-overlay';
import useAnalytics from '../../hooks/useAnalytics';
import ReactGA from 'react-ga4';

function CreateAccount() {
  const [password, setPassword] = useState<string>('');
  const [country, setCountry] = useState<ICountry>(supportedCountries.EG);
  const [phone, setPhone] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  const passwordRegex = new RegExp(
    '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$',
  );
  const isPasswordValid = password.length >= 8 && passwordRegex.test(password);
  const isFormValid = phone.length >= 6 && isPasswordValid;
  const { eventTracker } = useAnalytics('Create Account');

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: window.location.pathname,
      title: 'Create Account Page',
    });
  }, []);

  async function submit() {
    setLoading(true);
    eventTracker({ action: 'create_account', label: 'submitted' });
    const phoneNumber = `+${country.phone ?? '1'}${phone}`;
    try {
      localStorage.setItem('phone', phoneNumber);
      localStorage.setItem('tempPass', password);
      await signUp(phoneNumber, password);
      setLoading(false);
      navigate('/verify-account');
      eventTracker({ action: 'create_account', label: 'success' });
    } catch (err: any) {
      setLoading(false);
      eventTracker({ action: 'create_account', label: 'failure' });
    }
  }
  return (
    <LoadingOverlay active={loading} spinner text="Creating your account...">
      <div className="create-account">
        <h1>Sign up</h1>
        <Form>
          <Form.Group className="mb-3" controlId="phone">
            <Form.Label>Phone number⋆</Form.Label>
            <CountrySelector
              isDisabled={false}
              isPhoneInput
              countryCode={country.isoCode}
              onChange={setCountry}
              onPhoneChange={setPhone}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="password">
            <Form.Label>Password⋆</Form.Label>
            <Form.Control
              type="password"
              placeholder="Enter your desired password"
              isValid={isPasswordValid}
              isInvalid={!isPasswordValid}
              onChange={(e) => setPassword(e.target.value)}
            />
            <Form.Text>
              Must have a minimum of 8 characters and contain 1 uppercase
              character, 1 number and 1 special character #?!@$%^&*-"
            </Form.Text>
          </Form.Group>
        </Form>

        <div className="d-grid bottom-container">
          <p>By pressing “Continue” you have read and agreed to Blassa’s</p>
          <a href="https://www.blassa.io/privacy-policy">
            user terms and conditions.
          </a>
          <Button
            disabled={!isFormValid}
            variant={!isFormValid ? 'disabled' : 'primary'}
            size="lg"
            onClick={submit}>
            Continue
          </Button>
        </div>
      </div>
    </LoadingOverlay>
  );
}

export default CreateAccount;
