import { useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';

export default function Profile() {
  const [user, setUser] = useState<any>(undefined);
  const navigate = useNavigate();

  async function loadUser() {
    const fetchedUser = await Auth.currentAuthenticatedUser();
    setUser(fetchedUser);
  }
  useEffect(() => {
    loadUser();
  }, []);

  useEffect(() => {
    if (
      user &&
      (user.attributes?.given_name === 'Guest' ??
        user.attributes?.family_name === 'Guest')
    ) {
      navigate('/profile/edit');
    }
  }, [user, navigate]);
  return (
    <div>
      <h1>
        Welcome {user?.attributes?.given_name} {user?.attributes?.family_name}
      </h1>
    </div>
  );
}
