import ReactSelect from 'react-select';
import { Col, Form, Row } from 'react-bootstrap';
import {
  ADDRESS_FIELDS_NAMES,
  Field,
  FieldSize,
  LEVEL_1_DIVISION_NAMES_PER_COUNTRY,
} from '../CountrySelector/countries';
import { SA_NAMES } from '../CountrySelector/divisions/sa_names';
import { TN_NAMES } from '../CountrySelector/divisions/tn_names';
import { useEffect, useState } from 'react';
import { EG_NAMES } from '../CountrySelector/divisions/eg_names';

type CountryCode = 'TN' | 'SA' | 'EG';

interface ICustomFormProps {
  config?: Field[];
  addressFields: any;
  countryCode: string;
  onChange: (fieldName: string, value: string) => void;
}

function CustomForm({
  config,
  onChange,
  addressFields,
  countryCode,
}: ICustomFormProps) {
  const [refreshKey, setRefreshKey] = useState(0);
  const [mainDivision, setMainDivision] = useState(
    addressFields[
      LEVEL_1_DIVISION_NAMES_PER_COUNTRY[countryCode as CountryCode]
    ],
  );

  useEffect(() => {
    if (
      addressFields[
        LEVEL_1_DIVISION_NAMES_PER_COUNTRY[countryCode as CountryCode]
      ] !== mainDivision
    ) {
      setMainDivision(
        addressFields[
          LEVEL_1_DIVISION_NAMES_PER_COUNTRY[countryCode as CountryCode]
        ],
      );
      setRefreshKey((prevKey) => prevKey + 1);
    }
  }, [addressFields, countryCode, mainDivision]);

  return (
    <Row key={refreshKey}>
      {(config ?? []).map((field: Field, index) => {
        let defaultValue;
        if (addressFields[field.name]) {
          if (field.type === 'options') {
            defaultValue = {
              label: addressFields[field.name],
              value: addressFields[field.name],
            };
          } else {
            defaultValue = addressFields[field.name];
          }
        }

        let fieldOptions;
        if (field.name === ADDRESS_FIELDS_NAMES.CITY) {
          switch (countryCode) {
            case 'SA':
              if (addressFields[ADDRESS_FIELDS_NAMES.PROVINCE]) {
                const province: string =
                  addressFields[ADDRESS_FIELDS_NAMES.PROVINCE];
                fieldOptions = Object.keys(SA_NAMES[province as string].cities);
              }
              break;

            case 'TN':
              if (addressFields[ADDRESS_FIELDS_NAMES.STATE]) {
                const state: string = addressFields[ADDRESS_FIELDS_NAMES.STATE];
                fieldOptions = Object.keys(TN_NAMES[state as string].cities);
              }
              break;
          }
        } else if (
          field.name === ADDRESS_FIELDS_NAMES.DISTRICT &&
          field.type === 'options'
        ) {
          switch (countryCode) {
            case 'EG':
              if (addressFields[ADDRESS_FIELDS_NAMES.GOVERNORATE]) {
                const governorate: string =
                  addressFields[ADDRESS_FIELDS_NAMES.GOVERNORATE];
                fieldOptions = Object.keys(
                  EG_NAMES[governorate as string].districts,
                );
              }
              break;

            case 'SA':
              if (addressFields[ADDRESS_FIELDS_NAMES.PROVINCE]) {
                const province: string =
                  addressFields[ADDRESS_FIELDS_NAMES.PROVINCE];
                fieldOptions = Object.keys(
                  SA_NAMES[province as string].districts,
                );
              }
              break;

            case 'TN':
              if (addressFields[ADDRESS_FIELDS_NAMES.STATE]) {
                const state: string = addressFields[ADDRESS_FIELDS_NAMES.STATE];
                fieldOptions = Object.keys(TN_NAMES[state as string].districts);
              }
              break;
          }
        }

        return (
          <Col xs={field.size === FieldSize.LG ? 12 : 6} key={`field_${index}`}>
            {field.type === 'options' ? (
              <Form.Group className="mb-3" controlId={field.name}>
                <Form.Label>
                  {field.placeHolder}
                  {field.required ? '*' : ''}
                </Form.Label>
                <ReactSelect
                  options={(field.options ?? fieldOptions ?? []).map((e) => ({
                    value: e,
                    label: e,
                  }))}
                  onChange={(item) => onChange(field.name, item?.value ?? '')}
                  defaultValue={defaultValue}
                />
              </Form.Group>
            ) : field.name !== ADDRESS_FIELDS_NAMES.STREET ? (
              <Form.Group className="mb-3" controlId={field.name}>
                <Form.Label>
                  {field.placeHolder}
                  {field.required ? '*' : ''}
                </Form.Label>
                <Form.Control
                  type="text"
                  defaultValue={addressFields[field.name] ?? undefined}
                  placeholder={field.placeHolder}
                  onChange={(e) => onChange(field.name, e.target.value)}
                />
              </Form.Group>
            ) : (
              <></>
            )}
          </Col>
        );
      })}
    </Row>
  );
}

export default CustomForm;
