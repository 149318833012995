import axios from 'axios';
import ReactGA from 'react-ga4';
import { toast, ToastContainer } from 'react-toastify';
import useAnalytics from '../../hooks/useAnalytics';
import { useEffect, useRef, useState } from 'react';
import useIntegrations from '../../hooks/useIntegrations';
import { useMatches, useNavigate } from 'react-router-dom';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import mapboxgl from 'mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import {
  Field,
  supportedCountries,
} from '../../components/CountrySelector/countries';
// @ts-ignore
import LoadingOverlay from 'react-loading-overlay';
import './style.scss';
import { reverseGeocodeLocation } from '../../utils/geocoding-utils';
import { MAPBOX_TOKEN, mapboxReverseGeocode } from '../../utils/mapbox';
import { mixpanel } from '../../utils/mixpanel';

// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved
/* mapboxgl.workerClass =
  require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;
*/
mapboxgl.accessToken = MAPBOX_TOKEN;

function RefineLocation() {
  const navigate = useNavigate();
  const matches = useMatches();
  const [loading, setLoading] = useState<boolean>(false);
  const [isCountrySupported, setIsCountrySupported] = useState<boolean>(false);
  const [addressRequestJobId, setAddressRequestJobId] = useState<string>();
  const [center, setCenter] = useState<{ lng: number; lat: number }>({
    lat: 36.806136,
    lng: 10.181506,
  });
  const [addressText, setAddressText] = useState<string>('');
  const {
    loading: integrationLoading,
    orderDetails,
    updateIntegrationId,
    getIntegration,
    isIntegrationIdExpired,
  } = useIntegrations();

  const map = useRef<mapboxgl.Map | null>(null);
  const mapContainer = useRef(null);

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: window.location.pathname,
      title: 'Refine Location',
    });
  }, []);

  useEffect(() => {
    console.log('Inside match use effect');
    if (matches && matches.length > 0 && matches[0].params.integrationId) {
      setAddressRequestJobId(matches[0].params.integrationId);
      console.log('Set address request job id', matches);
    }
  }, [matches]);

  useEffect(() => {
    console.log('Inside getAddressRequestJob use effect');
    if (addressRequestJobId && addressRequestJobId !== '') {
      getIntegration(addressRequestJobId);
      console.log('called GetAddressRequestJob', addressRequestJobId);
    }
  }, [addressRequestJobId]);

  async function addressToPoint() {
    if (addressText) {
      try {
        const response = await axios.get(
          `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURI(
            addressText,
          )}.json?access_token=pk.eyJ1IjoiYW1lbC1kZXYiLCJhIjoiY2w5dHYzam1vMTM4czQ2bGZicTMxcGg4eCJ9.z3qgKLBdeoUTjHFRZAFAwg&limit=1`,
        );
        if (response.data?.features?.[0]?.geometry?.coordinates) {
          setCenter({
            lng: response.data?.features?.[0]?.geometry?.coordinates[0],
            lat: response.data?.features?.[0]?.geometry?.coordinates[1],
          });
          if (map.current) {
            map.current.flyTo({
              center: response.data?.features?.[0]?.geometry?.coordinates,
              zoom: 14,
            });
          }
        } else {
          toast.error('Could not determin the location', {
            position: toast.POSITION.TOP_RIGHT,
          });

          eventTracker({
            action: 'refine_location_init',
            label: 'failure_address_search',
          });
        }
      } catch (err) {
        eventTracker({
          action: 'refine_location_init',
          label: 'failure_address_search',
        });
      }
    }
  }

  useEffect(() => {
    if (addressText) {
      addressToPoint();
    }
  }, [addressText]);

  useEffect(() => {
    if (map.current) return; // initialize map only once
    map.current = new mapboxgl.Map({
      container: mapContainer.current ?? 'mapbox-container',
      style: 'mapbox://styles/mapbox/streets-v12',
      center,
      zoom: 12,
    });
    map.current.on('dragend', () => {
      eventTracker({
        action: 'refine_location_interaction',
        label: 'drag_map',
      });
      const newCenter = map.current?.getCenter();
      if (newCenter?.lat && newCenter.lng) {
        setCenter({ lng: newCenter.lng, lat: newCenter.lat });
        eventTracker({
          action: 'refine_location_interaction',
          label: 'drag_map_success',
        });
      } else {
        eventTracker({
          action: 'refine_loction_interaction',
          label: 'drag_map_failure',
        });
        // Error retrieving center coordinates.
      }
    });

    map.current.on('zoomend', () => {
      eventTracker({
        action: 'refine_location_interaction',
        label: 'zoom_map',
      });
      const newCenter = map.current?.getCenter();
      if (newCenter?.lat && newCenter.lng) {
        setCenter({ lng: newCenter.lng, lat: newCenter.lat });
        eventTracker({
          action: 'refine_location_interaction',
          label: 'zoom_map_success',
        });
      } else {
        eventTracker({
          action: 'refine_loction_interaction',
          label: 'zoom_map_failure',
        });
        // Error retrieving center coordinates.
      }
    });
    if (navigator.geolocation) {
      locateMe();
    }
  }, []);

  useEffect(() => {
    async function fetchData() {
      try {
        const result = await mapboxReverseGeocode({
          latitude: center.lat,
          longitude: center.lng,
        });
        if (result.countryCode.toUpperCase() in supportedCountries) {
          setIsCountrySupported(true);
        } else {
          setIsCountrySupported(false);
        }
      } catch (error) {
        setIsCountrySupported(false);
        console.log(error);
      }
    }
    fetchData();
  }, [center]);

  const { eventTracker } = useAnalytics('Address Verification');
  async function locateMe() {
    eventTracker({ action: 'refine_location_interaction', label: 'locate_me' });
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setCenter({
            lng: position.coords.longitude,
            lat: position.coords.latitude,
          });
          map.current?.flyTo({
            center: [position.coords.longitude, position.coords.latitude],
            animate: false,
            zoom: 14,
          });
          eventTracker({
            action: 'refine_location_interaction',
            label: 'locate_me_success',
          });
        },
        (error) => {
          toast.error(error.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          eventTracker({
            action: 'refine_location_interaction',
            label: 'locate_me_failure',
          });
        },
      );
    } else {
      toast.error('cannot get location', {
        position: toast.POSITION.TOP_RIGHT,
      });
      eventTracker({
        action: 'refine_location_interaction',
        label: 'locate_me_missing_permissions',
      });
    }
  }

  async function submit() {
    setLoading(true);
    eventTracker({ action: 'submit_refine_location', label: 'clicked' });

    // Reverse geocode location.
    let reverseGeocodingResponse;
    try {
      reverseGeocodingResponse = await reverseGeocodeLocation({
        lat: center.lat,
        lng: center.lng,
      });
      let reverseGeocodedAddress: any = {};
      let streetNames;

      if (reverseGeocodingResponse.addressFields) {
        reverseGeocodedAddress = {
          type: 'House',
          countryCode: reverseGeocodingResponse.countryCode,
          addressFields: reverseGeocodingResponse.addressFields,
          geoLocation: {
            type: 'Point',
            coordinates: {
              latitude: center.lat,
              longitude: center.lng,
            },
          },
        };
        streetNames = Array.from(
          new Set(
            reverseGeocodingResponse.streetSuggestions
              .map((street: { name: string }) => street.name)
              .filter((name: any) => name !== null),
          ),
        );
      } else {
        const result = await mapboxReverseGeocode({
          latitude: center.lat,
          longitude: center.lng,
        });
        const countrySchema = supportedCountries[
          result.countryCode
        ].addFields.House.map((field: Field) => field.name);
        const filteredResult = Object.fromEntries(
          Object.entries(result).filter(([key]) => countrySchema.includes(key)),
        );
        reverseGeocodedAddress = {
          type: 'House',
          countryCode: result.countryCode,
          addressFields: filteredResult,
          geoLocation: {
            type: 'Point',
            coordinates: {
              latitude: center.lat,
              longitude: center.lng,
            },
          },
        };
      }

      try {
        const updateAddressRequestJobResponse = await updateIntegrationId(
          addressRequestJobId as string,
          reverseGeocodedAddress,
        );

        if (updateAddressRequestJobResponse.data) {
          eventTracker({ action: 'refined_location_submit', label: 'success' });
          navigate(`/${addressRequestJobId}/address-details`, {
            state: {
              addressRequestJob: updateAddressRequestJobResponse.data,
              streetSuggestions: streetNames,
            },
          });
          mixpanel.track('Update Location', {
            status: 'success',
            integrationId: addressRequestJobId,
            country: reverseGeocodedAddress.countryCode,
          });
        }
      } catch (error) {
        setLoading(false);

        // @ts-ignore
        toast.error(error.response?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        eventTracker({ action: 'refined_location_submit', label: 'failure' });
        mixpanel.track('Update Location', {
          status: 'failure',
          integrationId: addressRequestJobId,
        });
      }
    } catch (error: any) {
      setLoading(false);
      if (error.response?.data?.statusCode === 404) {
        toast.error('This country is not supported yet', {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      mixpanel.track('Update Location', {
        status: 'failure',
        integrationId: addressRequestJobId,
      });
    }
    setLoading(false);
  }

  return (
    <LoadingOverlay
      active={loading || integrationLoading}
      spinner
      text="Loading your content...">
      {!isIntegrationIdExpired ? (
        <Container className="refine-location">
          <Container>
            <h1>Your order from {orderDetails?.orderSource}</h1>
            <Row>
              <Col>
                <p>
                  Order <span>#{orderDetails?.orderNumber}</span>
                </p>
              </Col>
            </Row>
          </Container>
          <div id="mapbox-container" ref={mapContainer}>
            <img
              src="/assets/img/marker.png"
              className="marker"
              alt="location-marker-icon"
            />
            <div className="search">
              <Form.Group className="mb-3" controlId="name">
                <Form.Control
                  type="text"
                  style={{ width: '100%' }}
                  placeholder="Enter your address"
                  value={addressText}
                  onChange={(e) => {
                    eventTracker({
                      action: 'refine_location_interaction',
                      label: 'search_text',
                    });
                    setAddressText(e.target.value);
                  }}
                />
                <img src="/assets/img/search.png" alt="search-icon" />
              </Form.Group>
              <div className="instruction-container">
                Search or just move the map to your location
              </div>
              {!isCountrySupported && (
                <div className="warning-container">
                  We don't support this country yet
                </div>
              )}
            </div>
            <button className="locate" onClick={locateMe}>
              <div className="content">
                <span className="message">Bring pin to current location</span>
                <img src="/assets/img/locate.png" alt="current-location-icon" />
              </div>
            </button>
          </div>
          <Form style={{ padding: 20 }}>
            <div className="d-grid gap-2">
              <Button
                variant="primary"
                size="lg"
                onClick={submit}
                disabled={!isCountrySupported}>
                Continue
              </Button>
            </div>
          </Form>
          <ToastContainer />
        </Container>
      ) : (
        <Container className="centered-container">
          <div className="image-container">
            <img
              src="/assets/img/broken-link.png"
              className="error-icon"
              alt="error-icon"
            />
          </div>
          <h1>Your integration link has expired!</h1>
          <ToastContainer />
        </Container>
      )}
    </LoadingOverlay>
  );
}

export default RefineLocation;
