import i18n from 'i18next';

import { EG_NAMES } from './divisions/eg_names';
import { SA_NAMES } from './divisions/sa_names';
import { TN_NAMES } from './divisions/tn_names';

// TODO - get this from the common package.

export enum FieldSize {
  XS = 'XS',
  LG = 'LG',
}
export interface Field {
  name: string;
  placeHolder: string;
  size: FieldSize;
  required: boolean;
  options?: string[];
  type?: string;
  value?: string;
}

export interface FieldConfig {
  House: Field[];
  Apartment: Field[];
  Office: Field[];
}

export type ICountry = {
  name: string;
  emoji: string;
  unicode: string;
  image: string;
  isoCode: string;
  addFields: FieldConfig;
  phone: string;
};

export interface ICountries {
  [key: string]: ICountry;
}

export const SUPPORTED_COUNTRY_CODES = ['eg', 'tn', 'ae', 'sa'];

export const SUPPORTED_COUNTRY_NAMES = {
  EG: 'Egypt',
  TN: 'Tunisia',
  AE: 'United_Arab_Emirates',
  SA: 'Saudi_Arabia',
  MA: 'Morocco',
};

export const ADDRESS_FIELDS_NAMES = {
  NEIGHBORHOOD: 'neighborhood',
  CITY: 'city',
  DISTRICT: 'district',
  BUILDING_NUMBER: 'buildingNumber',
  OFFICE_NUMBER: 'officeNumber',
  POSTAL_CODE: 'postalCode',
  SHORT_ADDRESS: 'shortAddress',
  FLOOR_NUMBER: 'floorNumber',
  APARTMENT_NUMBER: 'apartmentNumber',
  BLOCK_NUMBER: 'blockNumber',
  ROAD_NUMBER: 'roadNumber',
  AREA: 'area',
  STATE: 'state',
  GOVERNORATE: 'governorate',
  PROVINCE: 'province',
  STREET: 'street',
};

export const ADDRESS_FIELDS_PLACEHOLDERS = {
  NEIGHBORHOOD: 'Neighborhood',
  CITY: 'City',
  DISTRICT: 'District',
  BUILDING_NUMBER: 'Building Number',
  OFFICE_NUMBER: 'Office Number',
  POSTAL_CODE: 'Postal Code',
  SHORT_ADDRESS: 'Short Address',
  FLOOR_NUMBER: 'Floor Number',
  APARTMENT_NUMBER: 'Apartment Number',
  BLOCK_NUMBER: 'Block Number',
  ROAD_NUMBER: 'Road Number',
  AREA: 'Area',
  STATE: 'State',
  GOVERNORATE: 'Governorate',
  PROVINCE: 'Province',
  STREET: 'Street',
};

export const SUPPORTED_COUNTRY_STATES = {
  TN: {
    en: {
      Tunis: 'Tunis',
      Ariana: 'Ariana',
      'Ben Arous': 'Ben Arous',
      Manouba: 'Manouba',
      Bizerte: 'Bizerte',
      Béja: 'Béja',
      Jendouba: 'Jendouba',
      Nabeul: 'Nabeul',
      Zaghouan: 'Zaghouan',
      Sousse: 'Sousse',
      Monastir: 'Monastir',
      Mahdia: 'Mahdia',
      Sfax: 'Sfax',
      Kairouan: 'Kairouan',
      Kasserine: 'Kasserine',
      'Sidi Bouzid': 'Sidi Bouzid',
      Gabès: 'Gabès',
      Medenine: 'Medenine',
      Tataouine: 'Tataouine',
      Tozeur: 'Tozeur',
      Kebili: 'Kébili',
      Gafsa: 'Gafsa',
      Siliana: 'Siliana',
      'Le Kef': 'Le Kef',
    },
    ar: {
      Tunis: 'تونس',
      Ariana: 'أريانة',
      'Ben Arous': 'بن عروس',
      Manouba: 'منوبة',
      Bizerte: 'بنزرت',
      Béja: 'باجة',
      Jendouba: 'جندوبة',
      Nabeul: 'نابل',
      Zaghouan: 'زغوان',
      Sousse: 'سوسة',
      Monastir: 'المنستير',
      Mahdia: 'المهدية',
      Sfax: 'صفاقس',
      Kairouan: 'القيروان',
      Kasserine: 'القصرين',
      'Sidi Bouzid': 'سيدي بوزيد',
      Gabès: 'قابس',
      Medenine: 'مدنين',
      Tataouine: 'تطاوين',
      Tozeur: 'توزر',
      Kebili: 'قبلي',
      Gafsa: 'قفصة',
      Siliana: 'سليانة',
      'Le Kef': 'الكاف',
    },
  },
  SA: {
    en: {
      'Al-Riyadh': 'Al-Riyadh',
      Mecca: 'Mecca',
      Madinah: 'Madinah (Medina)',
      'Eastern Province': 'Eastern Province (Al-Sharqiyah)',
      Asir: 'Asir',
      Tabuk: 'Tabuk',
      'Al-Qassim': 'Al-Qassim',
      "Ha'il": "Ha'il",
      'Northern Borders': 'Northern Borders',
      Jizan: 'Jizan',
      Najran: 'Najran',
      'Al-Bahah': 'Al-Bahah',
      'Al-Jawf': 'Al-Jawf',
    },
    ar: {
      'Al-Riyadh': 'الرياض',
      Mecca: 'مكة المكرمة',
      Madinah: 'المدينة المنورة',
      'Eastern Province': 'المنطقة الشرقية',
      Asir: 'عسير',
      Tabuk: 'تبوك',
      'Al-Qassim': 'القصيم',
      "Ha'il": 'حائل',
      'Northern Borders': 'الحدود الشمالية',
      Jizan: 'جازان',
      Najran: 'نجران',
      'Al-Bahah': 'الباحة',
      'Al-Jawf': 'الجوف',
    },
  },
  MA: {
    en: {
      'Tangier-Tetouan-Al Hoceima': 'Tangier-Tetouan-Al Hoceima',
      Oriental: 'Oriental',
      'Fès-Meknès': 'Fes-Meknes',
      'Rabat-Sale-Kenitra': 'Rabat-Sale-Kenitra',
      'Beni Mellal-Khenifra': 'Beni Mellal-Khenifra',
      'Casablanca-Settat': 'Casablanca-Settat',
      Casablanca: 'Casablanca',
      'Marrakech-Safi': 'Marrakech-Safi',
      'Draa-Tafilalet': 'Draa-Tafilalet',
      'Souss-Massa': 'Souss-Massa',
      'Guelmim-Oued Noun': 'Guelmim-Oued Noun',
      'Laayoune-Sakia El Hamra': 'Laayoune-Sakia El Hamra',
      'Dakhla-Oued Ed Dahab': 'Dakhla-Oued Ed Dahab',
    },
    ar: {
      'Tangier-Tetouan-Al Hoceima': 'طنجة تطوان الحسيمة',
      Oriental: 'الشرقية',
      'Fès-Meknès': 'فاس مكناس',
      'Rabat-Sale-Kenitra': 'الرباط سلا القنيطرة',
      'Beni Mellal-Khenifra': 'بني ملال خنيفرة',
      'Casablanca-Settat': 'الدار البيضاء سطات',
      Casablanca: 'الدار البيضاء',
      'Marrakech-Safi': 'مراكش آسفي',
      'Draa-Tafilalet': 'درعة تافيلالت',
      'Souss-Massa': 'سوس ماسة',
      'Guelmim-Oued Noun': 'كلميم واد نون',
      'Laayoune-Sakia El Hamra': 'العيون الساقية الحمراء',
      'Dakhla-Oued Ed Dahab': 'الداخلة وادي الذهب',
    },
  },
  EG: {
    en: {
      Cairo: 'Cairo',
      Alexandria: 'Alexandria',
      Giza: 'Giza',
      Dakahlia: 'Dakahlia',
      Sharqia: 'Sharqia',
      Gharbia: 'Gharbia',
      Qalyubia: 'Qalyubia',
      Minya: 'Minya',
      Beheira: 'Beheira',
      Qena: 'Qena',
      Asyut: 'Asyut',
      Sohag: 'Sohag',
      Aswan: 'Aswan',
      Damietta: 'Damietta',
      'Beni Suef': 'Beni Suef',
      Faiyum: 'Faiyum',
      Ismailia: 'Ismailia',
      Luxor: 'Luxor',
      Suez: 'Suez',
      'Port Said': 'Port Said',
      'Red Sea': 'Red Sea',
      Matrouh: 'Matrouh',
      'Kafr El Sheikh': 'Kafr El Sheikh',
      'North Sinai': 'North Sinai',
      'South Sinai': 'South Sinai',
      'New Valley': 'New Valley',
      Monufia: 'Monufia',
      'South Sudan': 'South Sudan',
      'Al Wadi Al Jadid': 'Al Wadi Al Jadid',
    },
    ar: {
      Cairo: 'القاهرة',
      Alexandria: 'الإسكندرية',
      Giza: 'الجيزة',
      Dakahlia: 'الدقهلية',
      Sharqia: 'الشرقية',
      Gharbia: 'الغربية',
      Qalyubia: 'القليوبية',
      Minya: 'المنيا',
      Beheira: 'البحيرة',
      Qena: 'قنا',
      Asyut: 'أسيوط',
      Sohag: 'سوهاج',
      Aswan: 'أسوان',
      Damietta: 'دمياط',
      'Beni Suef': 'بني سويف',
      Faiyum: 'الفيوم',
      Ismailia: 'الإسماعيلية',
      Luxor: 'الأقصر',
      Suez: 'السويس',
      'Port Said': 'بورسعيد',
      'Red Sea': 'البحر الأحمر',
      Matrouh: 'مطروح',
      'Kafr El Sheikh': 'كفر الشيخ',
      'North Sinai': 'شمال سيناء',
      'South Sinai': 'جنوب سيناء',
      'New Valley': 'الوادي الجديد',
      Monufia: 'المنوفية',
      'South Sudan': 'جنوب السودان',
      'Al Wadi Al Jadid': 'الوادي الجديد',
    },
  },
  AE: {
    en: {
      'Abu Dhabi': 'Abu Dhabi',
      Dubai: 'Dubai',
      Sharjah: 'Sharjah',
      Ajman: 'Ajman',
      'Umm Al Quwain': 'Umm Al Quwain',
      Fujairah: 'Fujairah',
      'Ras Al Khaimah': 'Ras Al Khaimah',
    },
    ar: {
      'Abu Dhabi': 'أبو ظبي',
      Dubai: 'دبي',
      Sharjah: 'الشارقة',
      Ajman: 'عجمان',
      'Umm Al Quwain': 'أم القيوين',
      Fujairah: 'الفجيرة',
      'Ras Al Khaimah': 'رأس الخيمة',
    },
  },
};

// TODO - get this from the common package.
enum BHAreas {
  MANAMA = 'Manama',
  AL_MUHARRAQ = 'Al Muharraq',
  MADINAT_HAMAD = 'Madinat Hamad',
  MADINAT_ISSA = 'Madinat Isa',
  KAFLAN = 'Kaflan',
  KARBABAD = 'Karbabad',
  JABALAT_HABASHI = 'Jabalat Habashi',
  AYN_AD_DAR = 'Ayn ad Dar',
  HILLAT_ABD_AS_SALIH = 'Hillat Abd as Salih',
  AL_MUQSHA = 'Al Muqsha',
  AL_MUWAYLIGHAG = 'Al Muwaylighah',
  MANI = 'Mani',
  JIDD_HAFS = 'Jidd Hafs',
  AL_BUSATIN = 'Al Busaytin',
  HALAT_AN_NAIM = 'Halat an Naim',
  RAYYA = 'Rayya',
  ARAD = 'Arad',
  SAMAHIJ = 'Samahij',
  AD_DAYR = 'Ad Dayr',
  HALAT_AS_SULTAH = 'Halat as Sultah',
  AZ_ZIMAH = 'Az Zimmah',
  QALALI = 'Qalali',
  HALAT_AL_KHULAYFAT = 'Halat al Khulayfat',
  AL_HADD = 'Al Hadd',
  UMM_ASH_SHAJAR = 'Umm ash Shajar',
  HALAH = 'Halah',
  MARQUBAN = 'Marquban',
  SUFALAH = 'Sufalah',
  HALAT_UMM_AL_BAYD = 'Halat Umm al Bayd',
  AL_AKR = 'Al Akr',
  AL_MAAMIR = 'Al Maamir',
  JIDD_ALI = 'Jidd Ali',
  SITRAH = 'Sitrah',
  JURDAB = 'Jurdab',
  SANAD = 'Sanad',
  AL_KHARIJIYAH = 'Al Kharijiyah',
  KAWRAH = 'Kawrah',
  AL_HUJAYR = 'Al Hujayr',
  MAHAZZAH = 'Mahazzah',
  AN_NUWAYDIRAT = 'An Nuwaydirat',
  WADIYAN = 'Wadiyan',
  AL_JUBAYLAT = 'Al Jubaylat',
  SALMABAD = 'Salmabad',
  AL_QARYAH = 'Al Qaryah',
  TUBLI = 'Tubli',
  AR_RIFA = 'Ar Rifa',
  ALI = 'Ali',
  AL_QADAM = 'Al Qadam',
}

export const BHAreas_AR = [
  'أبو صيبع',
  'أبوقوة',
  'أم الحصم',
  'باربار',
  'البديع',
  'البسيتين',
  'البوكوارة',
  'البوغزال',
  'البحير',
  'البرهامة',
  'بني جمرة',
  'البلاد القديم',
  'بوري',
  'توبلي',
  'جبلة حبشي',
  'جد الحاج',
  'جد حفص',
  'جد علي',
  'جرداب',
  'جري الشيخ',
  'جزر حوار',
  'الجسرة',
  'الكورة',
  'المصلى',
  'المنطقة الدبلوماسية',
  'الماحوز',
  'المالكية',
  'مدينة حمد',
  'مدينة زايد',
  'مدينة عيسى',
  'المحرق',
  'المعامير',
  'مقابة',
  'المرخ',
  'المقشع',
  'المنامة',
  'النبيه صالح',
  'النعيم',
  'نويدرات',
  'الهجير',
  'الهملة',
  'هورة عوالي',
  'وادي السيل',
  'ضاحية السيف',
  'طشان',
  'عالي',
  'العدلية',
  'عذاري',
  'عراد',
  'عسكر',
  'العكر',
  'عوالي',
  'عين الدار',
  'الغريفة',
  'الفاتح',
  'القدم',
  'القرية',
  'القضيبية',
  'القفول',
  'قلالي',
  'القلعة',
  'كرانة',
  'كرباباد',
  'كرزكان',
  'الزنج',
  'الساية',
  'السقية',
  'سار',
  'اسكان سار',
  'سافرة',
  'سترة',
  'سلماباد',
  'السلمانية',
  'سماهيج',
  'السنابس',
  'السويفية',
  'سند',
  'السهلة (الشمالية والجنوبية)',
  'الشاخورة',
  'الشمالية',
  'شهركان',
  'الصخير',
  'الصالحيه',
  'الصافرية',
  'صدد',
  'الجفير',
  'الجنبية',
  'جنوسان',
  'جو',
  'الحجر',
  'الحد',
  'الحجيات',
  'حلة العبد الصالح',
  'الحورة',
  'الحنينية',
  'الخميس',
  'دار كليب',
  'الدراز',
  'دمستان',
  'الدير',
  'الديه',
  'رأس رمان',
  'الرفاع (الشرقي)',
  'الرفاع (الغربي)',
  'الرفاع (الشمالي)',
  'الزلاق',
];

const basicFields = [
  {
    name: ADDRESS_FIELDS_NAMES.STATE,
    placeHolder: ADDRESS_FIELDS_PLACEHOLDERS.STATE,
    required: true,
    size: FieldSize.XS,
  },
  {
    name: ADDRESS_FIELDS_NAMES.BUILDING_NUMBER,
    placeHolder: ADDRESS_FIELDS_PLACEHOLDERS.BUILDING_NUMBER,
    required: false,
    size: FieldSize.XS,
  },
  {
    name: ADDRESS_FIELDS_NAMES.CITY,
    placeHolder: ADDRESS_FIELDS_PLACEHOLDERS.CITY,
    required: false,
    size: FieldSize.XS,
  },
  {
    name: ADDRESS_FIELDS_NAMES.POSTAL_CODE,
    placeHolder: ADDRESS_FIELDS_PLACEHOLDERS.POSTAL_CODE,
    required: false,
    size: FieldSize.XS,
  },
  {
    name: ADDRESS_FIELDS_NAMES.STREET,
    placeHolder: ADDRESS_FIELDS_PLACEHOLDERS.STREET,
    required: false,
    size: FieldSize.LG,
    type: 'text',
  },
];

const baseFieldsEG = [
  {
    name: ADDRESS_FIELDS_NAMES.GOVERNORATE,
    placeHolder: ADDRESS_FIELDS_PLACEHOLDERS.GOVERNORATE,
    required: true,
    size: FieldSize.XS,
    type: 'options',
    options: Object.keys(EG_NAMES),
  },
  {
    name: ADDRESS_FIELDS_NAMES.DISTRICT,
    placeHolder: ADDRESS_FIELDS_PLACEHOLDERS.DISTRICT,
    required: false,
    size: FieldSize.XS,
    type: 'options',
  },
  {
    name: ADDRESS_FIELDS_NAMES.NEIGHBORHOOD,
    placeHolder: ADDRESS_FIELDS_PLACEHOLDERS.NEIGHBORHOOD,
    required: false,
    size: FieldSize.XS,
    type: 'text',
  },
  {
    name: ADDRESS_FIELDS_NAMES.POSTAL_CODE,
    placeHolder: ADDRESS_FIELDS_PLACEHOLDERS.POSTAL_CODE,
    required: false,
    size: FieldSize.XS,
  },
  {
    name: ADDRESS_FIELDS_NAMES.BUILDING_NUMBER,
    placeHolder: ADDRESS_FIELDS_PLACEHOLDERS.BUILDING_NUMBER,
    required: false,
    size: FieldSize.LG,
    type: 'text',
  },
  {
    name: ADDRESS_FIELDS_NAMES.STREET,
    placeHolder: ADDRESS_FIELDS_PLACEHOLDERS.STREET,
    required: false,
    size: FieldSize.LG,
    type: 'text',
  },
];

const baseFieldsSA: Field[] = [
  {
    name: ADDRESS_FIELDS_NAMES.PROVINCE,
    placeHolder: ADDRESS_FIELDS_PLACEHOLDERS.PROVINCE,
    required: true,
    size: FieldSize.XS,
    type: 'options',
    options: Object.keys(SA_NAMES),
  },
  {
    name: ADDRESS_FIELDS_NAMES.CITY,
    placeHolder: ADDRESS_FIELDS_PLACEHOLDERS.CITY,
    required: false,
    size: FieldSize.XS,
    type: 'options',
  },
  {
    name: ADDRESS_FIELDS_NAMES.DISTRICT,
    placeHolder: ADDRESS_FIELDS_PLACEHOLDERS.DISTRICT,
    required: false,
    size: FieldSize.XS,
    type: 'options',
  },
  {
    name: ADDRESS_FIELDS_NAMES.NEIGHBORHOOD,
    placeHolder: ADDRESS_FIELDS_PLACEHOLDERS.NEIGHBORHOOD,
    required: false,
    size: FieldSize.XS,
    type: 'text',
  },
  {
    name: ADDRESS_FIELDS_NAMES.POSTAL_CODE,
    placeHolder: ADDRESS_FIELDS_PLACEHOLDERS.POSTAL_CODE,
    required: false,
    size: FieldSize.XS,
  },
  {
    name: ADDRESS_FIELDS_NAMES.SHORT_ADDRESS,
    placeHolder: ADDRESS_FIELDS_PLACEHOLDERS.SHORT_ADDRESS,
    required: false,
    size: FieldSize.XS,
  },
  {
    name: ADDRESS_FIELDS_NAMES.BUILDING_NUMBER,
    placeHolder: ADDRESS_FIELDS_PLACEHOLDERS.BUILDING_NUMBER,
    required: false,
    size: FieldSize.XS,
    type: 'text',
  },
  {
    name: ADDRESS_FIELDS_NAMES.STREET,
    placeHolder: ADDRESS_FIELDS_PLACEHOLDERS.STREET,
    required: false,
    size: FieldSize.LG,
    type: 'text',
  },
];

const officeFieldsDefault = [
  {
    name: ADDRESS_FIELDS_NAMES.OFFICE_NUMBER,
    placeHolder: ADDRESS_FIELDS_PLACEHOLDERS.OFFICE_NUMBER,
    required: true,
    size: FieldSize.XS,
    type: 'text',
  },
  {
    name: ADDRESS_FIELDS_NAMES.FLOOR_NUMBER,
    placeHolder: ADDRESS_FIELDS_PLACEHOLDERS.FLOOR_NUMBER,
    required: true,
    size: FieldSize.XS,
    type: 'text',
  },
];

const apartmentFieldsDefault = [
  {
    name: ADDRESS_FIELDS_NAMES.APARTMENT_NUMBER,
    placeHolder: ADDRESS_FIELDS_PLACEHOLDERS.APARTMENT_NUMBER,
    required: true,
    size: FieldSize.XS,
    type: 'text',
  },
  {
    name: ADDRESS_FIELDS_NAMES.FLOOR_NUMBER,
    placeHolder: ADDRESS_FIELDS_PLACEHOLDERS.FLOOR_NUMBER,
    required: true,
    size: FieldSize.XS,
    type: 'text',
  },
];

export const defaultConfig = {
  House: basicFields,
  Apartment: [...apartmentFieldsDefault, ...basicFields],
  Office: [...officeFieldsDefault, ...basicFields],
};

const baseFieldsTN = [
  {
    name: ADDRESS_FIELDS_NAMES.STATE,
    placeHolder: ADDRESS_FIELDS_PLACEHOLDERS.STATE,
    required: true,
    size: FieldSize.XS,
    type: 'options',
    options: Object.keys(TN_NAMES),
  },
  {
    name: ADDRESS_FIELDS_NAMES.CITY,
    placeHolder: ADDRESS_FIELDS_PLACEHOLDERS.CITY,
    required: false,
    size: FieldSize.XS,
    type: 'options',
  },
  {
    name: ADDRESS_FIELDS_NAMES.DISTRICT,
    placeHolder: ADDRESS_FIELDS_PLACEHOLDERS.DISTRICT,
    required: false,
    size: FieldSize.XS,
    type: 'text',
  },
  {
    name: ADDRESS_FIELDS_NAMES.POSTAL_CODE,
    placeHolder: ADDRESS_FIELDS_PLACEHOLDERS.POSTAL_CODE,
    required: false,
    size: FieldSize.XS,
    type: 'number',
  },
  {
    name: ADDRESS_FIELDS_NAMES.BUILDING_NUMBER,
    placeHolder: ADDRESS_FIELDS_PLACEHOLDERS.BUILDING_NUMBER,
    required: false,
    size: FieldSize.LG,
    type: 'text',
  },
  {
    name: ADDRESS_FIELDS_NAMES.STREET,
    placeHolder: ADDRESS_FIELDS_PLACEHOLDERS.STREET,
    required: false,
    size: FieldSize.LG,
    type: 'text',
  },
];

const baseFieldsMA = [
  {
    name: ADDRESS_FIELDS_NAMES.CITY,
    placeHolder: ADDRESS_FIELDS_PLACEHOLDERS.CITY,
    required: false,
    size: FieldSize.XS,
    type: 'text',
  },
  {
    name: ADDRESS_FIELDS_NAMES.POSTAL_CODE,
    placeHolder: ADDRESS_FIELDS_PLACEHOLDERS.POSTAL_CODE,
    required: false,
    size: FieldSize.XS,
    type: 'number',
  },
  {
    name: ADDRESS_FIELDS_NAMES.BUILDING_NUMBER,
    placeHolder: ADDRESS_FIELDS_PLACEHOLDERS.BUILDING_NUMBER,
    required: false,
    size: FieldSize.LG,
    type: 'text',
  },
  {
    name: ADDRESS_FIELDS_NAMES.STREET,
    placeHolder: ADDRESS_FIELDS_PLACEHOLDERS.STREET,
    required: false,
    size: FieldSize.LG,
    type: 'text',
  },
];

const apartmentFieldsTN = [...apartmentFieldsDefault, ...baseFieldsTN];

const officeFieldsTN = [...officeFieldsDefault, ...baseFieldsTN];

const apartmentFieldsEG = [...apartmentFieldsDefault, ...baseFieldsEG];

const officeFieldsEG = [...officeFieldsDefault, ...baseFieldsEG];

const apartmentFieldsMA = [...apartmentFieldsDefault, ...baseFieldsMA];

const officeFieldsMA = [...officeFieldsDefault, ...baseFieldsMA];

const apartmentFieldsSA = [...apartmentFieldsDefault, ...baseFieldsSA];

const officeFieldsSA = [...officeFieldsDefault, ...baseFieldsSA];

export const supportedCountries: ICountries = {
  /* BH: {
   
    name: 'Bahrain',
    emoji: '🇧🇭',
    unicode: 'U+1F1E7 U+1F1ED',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BH.svg',
    isoCode: 'BH',
    addFields: defaultConfig,
    phone: '973',

  },     */
  TN: {
    name: 'Tunisia',
    emoji: '🇹🇳',
    unicode: 'U+1F1F9 U+1F1F3',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/TN.svg',
    isoCode: 'TN',
    addFields: {
      House: baseFieldsTN,
      Apartment: apartmentFieldsTN,
      Office: officeFieldsTN,
    },
    phone: '216',
  },
  EG: {
    name: 'Egypt',
    emoji: '🇪🇬',
    unicode: 'U+1F1EA U+1F1EC',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/EG.svg',
    isoCode: 'EG',
    addFields: {
      House: baseFieldsEG,
      Apartment: apartmentFieldsEG,
      Office: officeFieldsEG,
    },
    phone: '20',
  },
  MA: {
    name: 'Morocco',
    emoji: '🇲🇦',
    unicode: 'U+1F1F2 U+1F1E6',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/MA.svg',
    isoCode: 'MA',
    addFields: {
      House: baseFieldsMA,
      Apartment: apartmentFieldsMA,
      Office: officeFieldsMA,
    },
    phone: '212',
  },
  AE: {
    name: 'United Arab Emirates',
    emoji: '🇦🇪',
    unicode: 'U+1F1E6 U+1F1EA',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/AE.svg',
    isoCode: 'AE',
    addFields: defaultConfig,
    phone: '971',
  },
  SA: {
    name: 'Saudi Arabia',
    emoji: '🇸🇦',
    unicode: 'U+1F1F8 U+1F1E6',
    image:
      'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/SA.svg',
    isoCode: 'SA',
    addFields: {
      House: baseFieldsSA,
      Apartment: apartmentFieldsSA,
      Office: officeFieldsSA,
    },
    phone: '966',
  },
};

export const LEVEL_1_DIVISION_NAMES_PER_COUNTRY = {
  TN: 'state',
  SA: 'province',
  EG: 'governorate',
};

export const SUBDIVISION_FIELD_NAMES = {
  city: 'cities',
  district: 'districts',
  neighborhood: 'neighborhoods',
};

export const DIVISION_NAMES_PER_COUNTRY = {
  TN: TN_NAMES,
  SA: SA_NAMES,
  EG: EG_NAMES,
};
