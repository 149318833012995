export const SA_NAMES: { [key: string]: any } = {
  'Al-Riyadh': {
    englishName: 'Al-Riyadh',
    arabicName: 'الرياض',
    cities: {
      'Al-Riyadh': { englishName: 'Al-Riyadh', arabicName: 'الرياض' },
      'Al-Diriyah': { englishName: 'Al-Diriyah', arabicName: 'الدرعية' },
      Huraymila: { englishName: 'Huraymila', arabicName: 'حريملاء' },
      Ramah: { englishName: 'Ramah', arabicName: 'رماح' },
      Dhurma: { englishName: 'Dhurma', arabicName: 'ظرماء' },
      'Al-Kharj': { englishName: 'Al-Kharj', arabicName: 'الخرج' },
      'Al-Dilam': { englishName: 'Al-Dilam', arabicName: 'الدلم' },
      Murat: { englishName: 'Murat', arabicName: 'مرات' },
      'Al-Dawadimi': { englishName: 'Al-Dawadimi', arabicName: 'الدوادمي' },
      Thadig: { englishName: 'Thadig', arabicName: 'ثادق' },
      'Al-Majmaah': { englishName: 'Al-Majmaah', arabicName: 'المجمعة' },
      Shaqraa: { englishName: 'Shaqraa', arabicName: 'شقراء' },
      'Al-Ghat': { englishName: 'Al-Ghat', arabicName: 'الغاط' },
      'Al-Zulfi': { englishName: 'Al-Zulfi', arabicName: 'الزلفي' },
      'Al-Mazahmiyah': {
        englishName: 'Al-Mazahmiyah',
        arabicName: 'المزاحمية',
      },
      'Al-Hariq': { englishName: 'Al-Hariq', arabicName: 'الحريق' },
      'Hotat Bani Tamim': {
        englishName: 'Hotat Bani Tamim',
        arabicName: 'حوطة بني تميم',
      },
      'Al-Aflaj': { englishName: 'Al-Aflaj', arabicName: 'الأفلاج' },
      'As Sulayyil': { englishName: 'As Sulayyil', arabicName: 'السليل' },
      'Wadi Al-Dawasir': {
        englishName: 'Wadi Al-Dawasir',
        arabicName: 'وادي الدواسر',
      },
      Afif: { englishName: 'Afif', arabicName: 'عفيف' },
      'Al-Qwayiyah': { englishName: 'Al-Qwayiyah', arabicName: 'القويعية' },
      'Al-Rine': { englishName: 'Al-Rine', arabicName: 'الرين' },
    },
    districts: {
      'Al-Malaz': { englishName: 'Al-Malaz', arabicName: 'الملز' },
      'Al-Olaya': { englishName: 'Al-Olaya', arabicName: 'العليا' },
      'Al-Shumaisi': { englishName: 'Al-Shumaisi', arabicName: 'الشميسي' },
      'Al-Madher': { englishName: 'Al-Madher', arabicName: 'المعذر' },
      'Al-Nassim': { englishName: 'Al-Nassim', arabicName: 'النسيم' },
      'Al-Rawdha': { englishName: 'Al-Rawdha', arabicName: 'الروضة' },
      Irqah: { englishName: 'Irqah', arabicName: 'ارقا' },
      'Al-Uraija': { englishName: 'Al-Uraija', arabicName: 'العريجاء' },
      'Al-Batha': { englishName: 'Al-Batha', arabicName: 'البطحاء' },
      'Al-Sulay': { englishName: 'Al-Sulay', arabicName: 'السلي' },
      'Al-Azayzia': { englishName: 'Al-Azayzia', arabicName: 'العزيزية' },
      Namar: { englishName: 'Namar', arabicName: 'نمار' },
      'Al-Shamal': { englishName: 'Al-Shamal', arabicName: 'الشمال' },
      'Al-Shifa': { englishName: 'Al-Shifa', arabicName: 'الشفاء' },
    },
  },
  'Al-Qaseem': {
    englishName: 'Al-Qaseem',
    arabicName: '',
    cities: {
      'Al-Assyah': { englishName: 'Al-Assyah', arabicName: 'الأسياح' },
      'Uqlat as Suqur': {
        englishName: 'Uqlat as Suqur',
        arabicName: 'عقلة الصقور',
      },
      Dhurayh: { englishName: 'Dhurayh', arabicName: 'ضريح' },
      'Ash Shamasiah': { englishName: 'Ash Shamasiah', arabicName: 'الشماسية' },
      'Al-Badaea': { englishName: 'Al-Badaea', arabicName: 'البدائع' },
      'Al-Bukayriyah': { englishName: 'Al-Bukayriyah', arabicName: 'البكيرية' },
      'Uyun Al-Jiwa': {
        englishName: 'Uyun Al-Jiwa',
        arabicName: 'عيون الجواء',
      },
      'Al-Nabhaniyah': {
        englishName: 'Al-Nabhaniyah',
        arabicName: 'النبهانية',
      },
      'Al-Rass': { englishName: 'Al-Rass', arabicName: 'الرس' },
      Onaizah: { englishName: 'Onaizah', arabicName: 'عنيزة' },
      'Al-Mudhnab': { englishName: 'Al-Mudhnab', arabicName: 'المذنب' },
    },
    districts: {},
  },
  Najran: {
    englishName: 'Najran',
    arabicName: '',
    cities: {
      Yadma: { englishName: 'Yadma', arabicName: 'يدمة' },
      'Badr Al-Janoub': {
        englishName: 'Badr Al-Janoub',
        arabicName: 'بدر الجنوب',
      },
      Najran: { englishName: 'Najran', arabicName: 'نجران' },
      Thar: { englishName: 'Thar', arabicName: 'ثار' },
      Khubash: { englishName: 'Khubash', arabicName: 'خباش' },
      Hubuna: { englishName: 'Hubuna', arabicName: 'هبونة' },
      Sharurah: { englishName: 'Sharurah', arabicName: 'شرورة' },
    },
    districts: {},
  },
  'Al-Baha': {
    englishName: 'Al-Baha',
    arabicName: '',
    cities: {
      'Al-Mandaq': { englishName: 'Al-Mandaq', arabicName: 'المندق' },
      'Al-Hijrah': { englishName: 'Al-Hijrah', arabicName: 'الهجرة' },
      'Al-Qara': { englishName: 'Al-Qara', arabicName: 'القرى' },
      'Al-Makhwah': { englishName: 'Al-Makhwah', arabicName: 'المخواة' },
      Quluwah: { englishName: 'Quluwah', arabicName: 'قلوة' },
      'Al-Ukayq': { englishName: 'Al-Ukayq', arabicName: 'العقيق' },
      "Far'ah Gamid Al-Zinad": {
        englishName: "Far'ah Gamid Al-Zinad",
        arabicName: 'فرعة جامد الزناد',
      },
      Baljurashi: { englishName: 'Baljurashi', arabicName: 'بلجرشي' },
      'Al-Bahah': { englishName: 'Al-Bahah', arabicName: 'الباحة' },
      'Bani Hasan': { englishName: 'Bani Hasan', arabicName: 'بني حسن' },
    },
    districts: {},
  },
  Hayel: {
    englishName: 'Hayel',
    arabicName: '',
    cities: {
      'Al-Hait': { englishName: 'Al-Hait', arabicName: 'الحيط' },
      'Al-Sulaimi': { englishName: 'Al-Sulaimi', arabicName: 'السليمي' },
      Hail: { englishName: 'Hail', arabicName: 'حائل' },
      'Al-Shamli': { englishName: 'Al-Shamli', arabicName: 'الشملي' },
      Muqqaq: { englishName: 'Muqqaq', arabicName: 'مققا' },
      'Al-Shanan': { englishName: 'Al-Shanan', arabicName: 'الشنان' },
      Baqaa: { englishName: 'Baqaa', arabicName: 'بقاء' },
      Samirah: { englishName: 'Samirah', arabicName: 'سميرة' },
      'Al-Ghazalah': { englishName: 'Al-Ghazalah', arabicName: 'الغزالة' },
    },
    districts: {},
  },
  'Al-Jouf': {
    englishName: 'Al-Jouf',
    arabicName: '',
    cities: {
      'Dawmat Al-Jandal': {
        englishName: 'Dawmat Al-Jandal',
        arabicName: 'ضُمَّة الجَندَل',
      },
      Tabarjal: { englishName: 'Tabarjal', arabicName: 'تبارجل' },
      'Al-Qurayat': { englishName: 'Al-Qurayat', arabicName: 'القريات' },
      Sakakah: { englishName: 'Sakakah', arabicName: 'سَكَّاكَة' },
    },
    districts: {},
  },
  'Northern Border': {
    englishName: 'Northern Border',
    arabicName: '',
    cities: {
      Rafha: { englishName: 'Rafha', arabicName: 'رفحاء' },
      Arar: { englishName: 'Arar', arabicName: 'عرعر' },
      'Al-Awqaila': { englishName: 'Al-Awqaila', arabicName: 'العوقيلة' },
      Turaif: { englishName: 'Turaif', arabicName: 'طريف' },
    },
    districts: {},
  },
};
