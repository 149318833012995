import ReactGA from 'react-ga4';

const useAnalytics = (category = 'Category') => {
  const eventTracker = (params: {
    action: string;
    label?: string;
    value?: number;
  }) => {
    const { action, label, value } = params;
    ReactGA.event({ category, action, label, value });
  };
  return { eventTracker };
};
export default useAnalytics;
