import { useEffect, useState } from 'react';
import { Button, Col, Form, InputGroup, Row } from 'react-bootstrap';
// @ts-ignore
import LoadingOverlay from 'react-loading-overlay';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import './style.scss';
import useIntegrations from '../../hooks/useIntegrations';
import ReactGA from 'react-ga4';
import useAnalytics from '../../hooks/useAnalytics';
import { useLocation, useNavigate } from 'react-router-dom';
import { mixpanel } from '../../utils/mixpanel';

function StreetSelection() {
  const { state } = useLocation();
  const [loading, setLoading] = useState<boolean>(false);
  const [storedStreetNames, setStoredStreetNames] = useState<any>(
    state.streetSuggestions,
  );
  const initialStreetNames: string[] = storedStreetNames ?? [];
  const [streets, setStreets] = useState<string[]>(initialStreetNames);
  const [selectedStreet, setSelectedStreet] = useState<string>(
    streets[0] || '',
  );
  const [customStreet, setCustomStreet] = useState<string>('');

  const {
    orderDetails,
    getOrderDetailsLocally,
    loading: integrationLoading,
    updateIntegrationId,
  } = useIntegrations();
  const { eventTracker } = useAnalytics('Address Verification');
  const navigate = useNavigate();

  useEffect(() => {
    getOrderDetailsLocally();
  }, []);

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: window.location.pathname,
      title: 'Street selection',
    });
  }, []);

  async function submit() {
    setLoading(true);
    const addressFields = {
      ...state.addressRequestJob.deliveryDetails.addressFields,
    };
    addressFields.street = selectedStreet;
    try {
      const updateAddressRequestJobResponse = await updateIntegrationId(
        state.addressRequestJob.id as string,
        {
          type: state.addressRequestJob.deliveryDetails.addressType,
          countryCode: state.addressRequestJob.deliveryDetails.countryCode,
          addressFields: addressFields,
          geoLocation: state.addressRequestJob.deliveryDetails.geoLocation,
        },
      );
      if (updateAddressRequestJobResponse.data) {
        eventTracker({ action: 'street_selection_submit', label: 'success' });
        navigate(`/${state.addressRequestJob.id}/delivery-window`, {
          state: { addressRequestJob: updateAddressRequestJobResponse.data },
        });
        mixpanel.track('Update Address Street', {
          status: 'success',
          integrationId: state.addressRequestJob.id,
          country: state.addressRequestJob.deliveryDetails.countryCode,
        });
      }
    } catch (error) {
      // @ts-ignore
      toast.error(error.response?.data?.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      eventTracker({ action: 'street_selection_submit', label: 'failure' });
      mixpanel.track('Update Address Street', {
        status: 'failure',
        integrationId: state.addressRequestJob.id,
      });
    }
    setLoading(false);
  }

  const handleStreetChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedStreet(e.target.value);
    setCustomStreet('');
  };

  const handleCustomStreetChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedStreet(e.target.value);
    setCustomStreet(e.target.value);
  };

  const handleDivClick = (
    e: React.MouseEvent<HTMLDivElement>,
    name: string,
  ) => {
    // Prevent the click event from the Form.Check component from bubbling up to the div
    if ((e.target as HTMLElement).tagName !== 'INPUT') {
      setSelectedStreet(name);
      setCustomStreet('');
    }
  };

  return (
    <LoadingOverlay
      active={loading || integrationLoading}
      spinner
      text="Submitting your content...">
      <div className="create-address">
        <h1>Your order from {orderDetails?.orderSource}</h1>
        <Row>
          <Col>
            <p>
              Order <span>#{orderDetails?.orderNumber}</span>
            </p>
          </Col>
        </Row>
        <h3>Street</h3>
        <Form>
          <Form.Label>Suggested streets</Form.Label>
          <div>
            {streets.slice(0, 5).map((name, index) => (
              <div
                key={index}
                className={`street-item ${
                  selectedStreet === name ? 'selected' : ''
                }`}
                onClick={(e) => handleDivClick(e, name)}>
                <div className="big-checkbox">
                  <span className="checkbox-label">{name}</span>
                  <Form.Check
                    reverse
                    type="radio"
                    value={name}
                    checked={selectedStreet === name}
                    onChange={handleStreetChange}
                    name="street"
                  />
                </div>
              </div>
            ))}
            <div>
              <Form.Group as={Row}>
                <InputGroup>
                  <InputGroup.Text
                    style={{ backgroundColor: 'transparent', border: 'none' }}>
                    <img
                      src="/assets/img/plus.png"
                      className="add-icon"
                      alt="plus icon"
                    />
                  </InputGroup.Text>
                  <Form.Control
                    className="mb-3 custom-input"
                    type="text"
                    style={{ border: 0 }}
                    value={customStreet}
                    placeholder="Enter a different street name"
                    onChange={handleCustomStreetChange}
                  />
                </InputGroup>
              </Form.Group>
            </div>
          </div>
          <div className="d-grid gap-2">
            <Button size="lg" onClick={submit} disabled={selectedStreet === ''}>
              Confirm street
            </Button>
          </div>
        </Form>
        <ToastContainer />
      </div>
    </LoadingOverlay>
  );
}

export default StreetSelection;
