export const EG_NAMES: { [key: string]: any } = {
  Cairo: {
    englishName: 'Cairo',
    arabicName: 'القاهرة',
    districts: {
      'Al-Matariyya': { englishName: 'Al-Matariyya', arabicName: 'المطرية' },
      Abdeen: { englishName: 'Abdeen', arabicName: 'عابدين' },
      'Ain Shams': { englishName: 'Ain Shams', arabicName: 'عين شمس' },
      '15 May': { englishName: '15 May', arabicName: '15 مايو' },
      'Al-Darb Al-Ahmar': {
        englishName: 'Al-Darb Al-Ahmar',
        arabicName: 'الدرب الأحمر',
      },
      'Al-Azbakeyah': { englishName: 'Al-Azbakeyah', arabicName: 'الأزبكية' },
      'Al-Basatin': { englishName: 'Al-Basatin', arabicName: 'البساتين' },
      'Al-Khalifa': { englishName: 'Al-Khalifa', arabicName: 'الخليفة' },
      Maadi: { englishName: 'Maadi', arabicName: 'المعادي' },
      Marg: { englishName: 'Marg', arabicName: 'المرج' },
      Matareya: { englishName: 'Matareya', arabicName: 'المطرية' },
      Musky: { englishName: 'Musky', arabicName: 'الموسكي' },
      Waily: { englishName: 'Waily', arabicName: 'الوايلي' },
      'Al-Nozha': { englishName: 'Al-Nozha', arabicName: 'النزهة' },
      'Masr Al-Qadima': {
        englishName: 'Masr Al-Qadima',
        arabicName: 'مصر القديمة',
      },
      'Al-Salam': { englishName: 'Al-Salam', arabicName: 'السلام' },
      'Sayyida Zainab': {
        englishName: 'Sayyida Zainab',
        arabicName: 'السيدة زينب',
      },
      'Al-Sharabiyya': { englishName: 'Al-Sharabiyya', arabicName: 'الشرابية' },
      Shroq: { englishName: 'Shroq', arabicName: 'شروق' },
      Tebin: { englishName: 'Tebin', arabicName: 'طيبة' },
      'Al-Zaytoun': { englishName: 'Al-Zaytoun', arabicName: 'الزيتون' },
      'Bab Al-Shariyya': {
        englishName: 'Bab Al-Shariyya',
        arabicName: 'باب الشعرية',
      },
      Badr: { englishName: 'Badr', arabicName: 'بدر' },
      Bolaq: { englishName: 'Bolaq', arabicName: 'بولاق' },
      'Hadayek Al-Qubba': {
        englishName: 'Hadayek Al-Qubba',
        arabicName: 'حدائق القبة',
      },
      Heliopolis: { englishName: 'Heliopolis', arabicName: 'مصر الجديدة' },
      Helwan: { englishName: 'Helwan', arabicName: 'حلوان' },
      'Nasr City': { englishName: 'Nasr City', arabicName: 'مدينة نصر' },
      'New Cairo': { englishName: 'New Cairo', arabicName: 'القاهرة الجديدة' },
      'Kasr Al-Nile': { englishName: 'Kasr Al-Nile', arabicName: 'قصر النيل' },
      'Rod Al-farag': { englishName: 'Rod Al-farag', arabicName: 'رمسيس' },
      Shubra: { englishName: 'Shubra', arabicName: 'شبرا' },
      Tura: { englishName: 'Tura', arabicName: 'طرة' },
      Zamalek: { englishName: 'Zamalek', arabicName: 'الزمالك' },
      'Zawya Al-Hamra': {
        englishName: 'Zawya Al-Hamra',
        arabicName: 'الزاوية الحمراء',
      },
      'Al-Gamaliyah': { englishName: 'Al-Gamaliyah', arabicName: 'الجمالية' },
      Sahel: { englishName: 'Sahel', arabicName: 'الساحل' },
      'Al-Daher': { englishName: 'Al-Daher', arabicName: 'الضاهر' },
      'Manshiet Nasir': {
        englishName: 'Manshiet Nasir',
        arabicName: 'منشية ناصر',
      },
      'Nasr City 2': { englishName: 'Nasr City 2', arabicName: 'مدينة نصر 2' },
      'New Cairo 2': {
        englishName: 'New Cairo 2',
        arabicName: 'القاهرة الجديدة 2',
      },
      'New Cairo 3': {
        englishName: 'New Cairo 3',
        arabicName: 'القاهرة الجديدة 3',
      },
      'New Administrative Capital': {
        englishName: 'New Administrative Capital',
        arabicName: 'العاصمة الإدارية الجديدة',
      },
    },
  },
  Dakahlia: {
    englishName: 'Dakahlia',
    arabicName: 'الدقهلية',
    districts: {
      Aga: { englishName: 'Aga', arabicName: 'آجا' },
      'Al-Jamaliyah': { englishName: 'Al-Jamaliyah', arabicName: 'الجمالية' },
      'Al-Mansurah': { englishName: 'Al-Mansurah', arabicName: 'المنصورة' },
      'Al-Mansurah 1': {
        englishName: 'Al-Mansurah 1',
        arabicName: 'المنصورة ١',
      },
      'Al-Mansurah 2': {
        englishName: 'Al-Mansurah 2',
        arabicName: 'المنصورة ٢',
      },
      'Al-Manzilah': { englishName: 'Al-Manzilah', arabicName: 'المنزلة' },
      'Al-Matariyah': { englishName: 'Al-Matariyah', arabicName: 'المطرية' },
      'Al-Senbellawein': {
        englishName: 'Al-Senbellawein',
        arabicName: 'السنبلاوين',
      },
      'Beni Ebeid': { englishName: 'Beni Ebeid', arabicName: 'بني عبيد' },
      Belqas: { englishName: 'Belqas', arabicName: 'بلقاس' },
      Dikirnis: { englishName: 'Dikirnis', arabicName: 'ديكرنس' },
      Gamasah: { englishName: 'Gamasah', arabicName: 'جمصة' },
      'Mahallat Damanah': {
        englishName: 'Mahallat Damanah',
        arabicName: 'محلة دمنة',
      },
      'Minyet Al-Nasr': {
        englishName: 'Minyet Al-Nasr',
        arabicName: 'منية النصر',
      },
      'Mit Ghamr': { englishName: 'Mit Ghamr', arabicName: 'ميت غمر' },
      'Mit Salsil': { englishName: 'Mit Salsil', arabicName: 'ميت سلسيل' },
      Nabaroh: { englishName: 'Nabaroh', arabicName: 'نبروه' },
      Shirbin: { englishName: 'Shirbin', arabicName: 'شربين' },
      Talkha: { englishName: 'Talkha', arabicName: 'طلخا' },
      'Timay Al-Imdid': {
        englishName: 'Timay Al-Imdid',
        arabicName: 'تماي العمد',
      },
    },
  },
  'Red Sea': {
    englishName: 'Red Sea',
    arabicName: 'البحر الأحمر',
    districts: {
      'Al-Ghurdaqah': { englishName: 'Al-Ghurdaqah', arabicName: 'الغردقة' },
      'Al-Ghurdaqah 2': {
        englishName: 'Al-Ghurdaqah 2',
        arabicName: 'الغردقة ٢',
      },
      'Al-Qusayr': { englishName: 'Al-Qusayr', arabicName: 'القصير' },
      'Al-Shalatin': { englishName: 'Al-Shalatin', arabicName: 'الشلاتين' },
      'Marsa Alam': { englishName: 'Marsa Alam', arabicName: 'مرسى علم' },
      'Ras Gharib': { englishName: 'Ras Gharib', arabicName: 'رأس غارب' },
      Safaja: { englishName: 'Safaja', arabicName: 'سفاجا' },
    },
  },
  'Al-Buhayrah': {
    englishName: 'Al-Buhayrah',
    arabicName: 'البحيرة',
    districts: {
      'Abu Al-Matamir': {
        englishName: 'Abu Al-Matamir',
        arabicName: 'أبو المطامير',
      },
      'Abu Hummus': { englishName: 'Abu Hummus', arabicName: 'أبو حمص' },
      'Al-Delengat': { englishName: 'Al-Delengat', arabicName: 'الدلنجات' },
      'Al-Mahmudiyah': {
        englishName: 'Al-Mahmudiyah',
        arabicName: 'المحمودية',
      },
      'Al-Rahmaniyah': {
        englishName: 'Al-Rahmaniyah',
        arabicName: 'الرحمانية',
      },
      Badr: { englishName: 'Badr', arabicName: 'بدر' },
      Damanhur: { englishName: 'Damanhur', arabicName: 'دمنهور' },
      'Hawsh Isa': { englishName: 'Hawsh Isa', arabicName: 'حوش عيسى' },
      Edku: { englishName: 'Edku', arabicName: 'إدكو' },
      'Itay Al-Barud': {
        englishName: 'Itay Al-Barud',
        arabicName: 'إيتاي البارود',
      },
      'Kafr Al-Dawwar': {
        englishName: 'Kafr Al-Dawwar',
        arabicName: 'كفر الدوار',
      },
      'Kom Hamada': { englishName: 'Kom Hamada', arabicName: 'كوم حمادة' },
      Rosetta: { englishName: 'Rosetta', arabicName: 'رشيد' },
      ShubraKhit: { englishName: 'ShubraKhit', arabicName: 'شبراخيت' },
      'Natrn Valley': {
        englishName: 'Natrn Valley',
        arabicName: 'وادي النطرون',
      },
      'West Nubariyah': {
        englishName: 'West Nubariyah',
        arabicName: 'النوبارية الغربية',
      },
    },
  },
  Alexandria: {
    englishName: 'Alexandria',
    arabicName: 'الإسكندرية',
    districts: {
      'Al-Dukhaylah': { englishName: 'Al-Dukhaylah', arabicName: 'الدخيلة' },
      'Al-Amriyah': { englishName: 'Al-Amriyah', arabicName: 'العامرية' },
      'Al-Atarin': { englishName: 'Al-Atarin', arabicName: 'العطارين' },
      'Al-Jumruk': { englishName: 'Al-Jumruk', arabicName: 'الجمرك' },
      'Al-Labban': { englishName: 'Al-Labban', arabicName: 'اللبان' },
      'Al-Manshiyah': { englishName: 'Al-Manshiyah', arabicName: 'المنشية' },
      'Al-Muntazah': { englishName: 'Al-Muntazah', arabicName: 'المنتزه' },
      'Al-Raml 1': { englishName: 'Al-Raml 1', arabicName: 'الرمل 1' },
      'Al-Raml 2': { englishName: 'Al-Raml 2', arabicName: 'الرمل 2' },
      'Bab Sharqi': { englishName: 'Bab Sharqi', arabicName: 'باب شرقي' },
      'Borg Al-Arab': { englishName: 'Borg Al-Arab', arabicName: 'برج العرب' },
      Karmouz: { englishName: 'Karmouz', arabicName: 'كرموز' },
      'Muharam Bik': { englishName: 'Muharam Bik', arabicName: 'المهرم بك' },
      'New Borg Al-Arab': {
        englishName: 'New Borg Al-Arab',
        arabicName: 'برج العرب الجديدة',
      },
      'Port Al-Basal': {
        englishName: 'Port Al-Basal',
        arabicName: 'ميناء البصل',
      },
      'Port Alexandria Police Dept.': {
        englishName: 'Port Alexandria Police Dept.',
        arabicName: 'قسم شرطة ميناء الإسكندرية',
      },
      'Sidi Gabir': { englishName: 'Sidi Gabir', arabicName: 'سيدي جابر' },
    },
  },
  Faiyum: {
    englishName: 'Faiyum',
    arabicName: 'الفيوم',
    districts: {
      'Al-Fayyum': { englishName: 'Al-Fayyum', arabicName: 'الفيوم' },
      'Al-Fayyum City': {
        englishName: 'Al-Fayyum City',
        arabicName: 'مدينة الفيوم',
      },
      Ibsheway: { englishName: 'Ibsheway', arabicName: 'إبشواي' },
      Itsa: { englishName: 'Itsa', arabicName: 'إطسا' },
      Sinnuris: { englishName: 'Sinnuris', arabicName: 'سنورس' },
      Tamiya: { englishName: 'Tamiya', arabicName: 'طامية' },
      'Yousef Seddik': {
        englishName: 'Yousef Seddik',
        arabicName: 'يوسف الصديق',
      },
    },
  },
  'Al-Gharbiyah': {
    englishName: 'Al-Gharbiyah',
    arabicName: 'الغربية',
    districts: {
      'Al-Mahalla Al-Kubra': {
        englishName: 'Al-Mahalla Al-Kubra',
        arabicName: 'المحلة الكبرى',
      },
      'Al-Mahalla Al-Kubra 1': {
        englishName: 'Al-Mahalla Al-Kubra 1',
        arabicName: 'المحلة الكبرى 1',
      },
      'Al-Mahalla Al-Kubra 2': {
        englishName: 'Al-Mahalla Al-Kubra 2',
        arabicName: 'المحلة الكبرى 2',
      },
      'Al-Sunta': { englishName: 'Al-Sunta', arabicName: 'السنطة' },
      Basyoun: { englishName: 'Basyoun', arabicName: 'بسيون' },
      'Kafr Al-Zayat': {
        englishName: 'Kafr Al-Zayat',
        arabicName: 'كفر الزيات',
      },
      Kotoor: { englishName: 'Kotoor', arabicName: 'قطور' },
      Samanoud: { englishName: 'Samanoud', arabicName: 'سمنود' },
      Tanta: { englishName: 'Tanta', arabicName: 'طنطا' },
      'Tanta 1': { englishName: 'Tanta 1', arabicName: 'طنطا 1' },
      'Tanta 2': { englishName: 'Tanta 2', arabicName: 'طنطا 2' },
      Zifta: { englishName: 'Zifta', arabicName: 'زفتى' },
    },
  },
  Ismailia: {
    englishName: 'Ismailia',
    arabicName: 'الإسماعيلية',
    districts: {
      'Al-Qantarah': { englishName: 'Al-Qantarah', arabicName: 'القنطرة' },
      'Al-Qantarah Al-Sharqiyah': {
        englishName: 'Al-Qantarah Al-Sharqiyah',
        arabicName: 'القنطرة الشرقية',
      },
      'Al-Tall Al-Kabir': {
        englishName: 'Al-Tall Al-Kabir',
        arabicName: 'التل الكبير',
      },
      Fayid: { englishName: 'Fayid', arabicName: 'الفيض' },
      Ismailia: { englishName: 'Ismailia', arabicName: 'الإسماعيلية' },
      'Ismailia 1': { englishName: 'Ismailia 1', arabicName: 'الإسماعيلية 1' },
      'Ismailia 2': { englishName: 'Ismailia 2', arabicName: 'الإسماعيلية 2' },
      'Ismailia 3': { englishName: 'Ismailia 3', arabicName: 'الإسماعيلية 3' },
    },
  },
  Giza: {
    englishName: 'Giza',
    arabicName: 'الجيزة',
    districts: {
      Dokki: { englishName: 'Dokki', arabicName: 'الدقي' },
      'Al-Aguza': { englishName: 'Al-Aguza', arabicName: 'العجوزة' },
      'Al-Ayyat': { englishName: 'Al-Ayyat', arabicName: 'العياط' },
      'Al-Omraniya': { englishName: 'Al-Omraniya', arabicName: 'العمرانية' },
      Pyramids: { englishName: 'Pyramids', arabicName: 'الأهرامات' },
      'Al-Badrashein': { englishName: 'Al-Badrashein', arabicName: 'البدرشين' },
      'Al-Hawamdeya': { englishName: 'Al-Hawamdeya', arabicName: 'الحوامدية' },
      'Al-Wahat Al-Bahariya': {
        englishName: 'Al-Wahat Al-Bahariya',
        arabicName: 'الواحات البحرية',
      },
      'Al-Warraq': { englishName: 'Al-Warraq', arabicName: 'الوراق' },
      'Al-Saff': { englishName: 'Al-Saff', arabicName: 'الصف' },
      Atfih: { englishName: 'Atfih', arabicName: 'العتفيه' },
      'Bulaq Al-Dakrour': {
        englishName: 'Bulaq Al-Dakrour',
        arabicName: 'بولاق الدكرور',
      },
      Giza: { englishName: 'Giza', arabicName: 'الجيزة' },
      Imbabah: { englishName: 'Imbabah', arabicName: 'إمبابة' },
      Kerdasa: { englishName: 'Kerdasa', arabicName: 'كرداسة' },
      'Sheikh Zayed': { englishName: 'Sheikh Zayed', arabicName: 'الشيخ زايد' },
      '6th of October 1 City': {
        englishName: '6th of October 1 City',
        arabicName: 'مدينة 6 أكتوبر 1',
      },
      '6th of October 2 City': {
        englishName: '6th of October 2 City',
        arabicName: 'مدينة 6 أكتوبر 2',
      },
    },
  },
  Monufia: {
    englishName: 'Monufia',
    arabicName: 'المنوفية',
    districts: {
      'Al-Bajur': { englishName: 'Al-Bajur', arabicName: 'الباجور' },
      'El Shohada': { englishName: 'El Shohada', arabicName: 'الشهداء' },
      Ashmoun: { englishName: 'Ashmoun', arabicName: 'أشمون' },
      'Birket Al-Sabaa': {
        englishName: 'Birket Al-Sabaa',
        arabicName: 'بركة السبع',
      },
      Menouf: { englishName: 'Menouf', arabicName: 'منوف' },
      'Menouf City': { englishName: 'Menouf City', arabicName: 'مدينة منوف' },
      Quweisna: { englishName: 'Quweisna', arabicName: 'القويسنة' },
      'Sadat City': { englishName: 'Sadat City', arabicName: 'مدينة السادات' },
      'Shibin Al-Kawm': {
        englishName: 'Shibin Al-Kawm',
        arabicName: 'شبين الكوم',
      },
      'Sers Al-Lyan': { englishName: 'Sers Al-Lyan', arabicName: 'سرس الليان' },
      Tala: { englishName: 'Tala', arabicName: 'طلة' },
    },
  },
  Minya: {
    englishName: 'Minya',
    arabicName: 'المنيا',
    districts: {
      'Abu Qurqas': { englishName: 'Abu Qurqas', arabicName: 'أبو قرقاص' },
      'Al-Idwa': { englishName: 'Al-Idwa', arabicName: 'العدوة' },
      'Al-Minya': { englishName: 'Al-Minya', arabicName: 'المنيا' },
      'Al-Minya City': {
        englishName: 'Al-Minya City',
        arabicName: 'مدينة المنيا',
      },
      'Beni Mazar': { englishName: 'Beni Mazar', arabicName: 'بني مزار' },
      'Dayr Mawas': { englishName: 'Dayr Mawas', arabicName: 'دير مواس' },
      Maghagha: { englishName: 'Maghagha', arabicName: 'مغاغة' },
      Mallawi: { englishName: 'Mallawi', arabicName: 'ملوي' },
      'Mallawi City': { englishName: 'Mallawi City', arabicName: 'مدينة ملوي' },
      Matay: { englishName: 'Matay', arabicName: 'مطاي' },
      'New Minya': { englishName: 'New Minya', arabicName: 'المنيا الجديدة' },
      Samalut: { englishName: 'Samalut', arabicName: 'سمالوط' },
    },
  },
  Qalyubiyya: {
    englishName: 'Qalyubiyya',
    arabicName: 'القليوبية',
    districts: {
      'Al-Ubour': { englishName: 'Al-Ubour', arabicName: 'العبور' },
      'Al-Khanka': { englishName: 'Al-Khanka', arabicName: 'الخانكة' },
      'Al-Khusus': { englishName: 'Al-Khusus', arabicName: 'الخصوص' },
      'Al-Qanater Al-Khayreya': {
        englishName: 'Al-Qanater Al-Khayreya',
        arabicName: 'القناطر الخيرية',
      },
      Banha: { englishName: 'Banha', arabicName: 'بنها' },
      'Kafr Shukr': { englishName: 'Kafr Shukr', arabicName: 'كفر شكر' },
      Qaha: { englishName: 'Qaha', arabicName: 'قها' },
      Qalyub: { englishName: 'Qalyub', arabicName: 'قليوب' },
      'Shibin Al-Qanater': {
        englishName: 'Shibin Al-Qanater',
        arabicName: 'شبين القناطر',
      },
      'Shubra Al-Kheima 1': {
        englishName: 'Shubra Al-Kheima 1',
        arabicName: 'شبرا الخيمة 1',
      },
      'Shubra Al-Kheima 2': {
        englishName: 'Shubra Al-Kheima 2',
        arabicName: 'شبرا الخيمة 2',
      },
      Tukh: { englishName: 'Tukh', arabicName: 'طوخ' },
    },
  },
  'New Valley': {
    englishName: 'New Valley',
    arabicName: 'الوادي الجديد',
    districts: {
      'Al-Wahatal Al-Kharijah': {
        englishName: 'Al-Wahatal Al-Kharijah',
        arabicName: 'الواحات الخارجة',
      },
      Baris: { englishName: 'Baris', arabicName: 'باريس' },
      'Al-Dakhlah': { englishName: 'Al-Dakhlah', arabicName: 'الداخلة' },
      'Al-Farafirah': { englishName: 'Al-Farafirah', arabicName: 'الفرافرة' },
    },
  },
  Matrouh: {
    englishName: 'Matrouh',
    arabicName: 'مطروح',
    districts: {
      'Al-Dabaa': { englishName: 'Al-Dabaa', arabicName: 'الضبعة' },
      'Al-Hammam': { englishName: 'Al-Hammam', arabicName: 'الحمام' },
      Sallum: { englishName: 'Sallum', arabicName: 'سلوم' },
      'Marsa Matruh': { englishName: 'Marsa Matruh', arabicName: 'مرسى مطروح' },
      'Sidi Barrani': { englishName: 'Sidi Barrani', arabicName: 'سيدي براني' },
      Siwa: { englishName: 'Siwa', arabicName: 'سيوة' },
      'North Coast': {
        englishName: 'North Coast',
        arabicName: 'الساحل الشمالي',
      },
    },
  },
  Qina: {
    englishName: 'Qina',
    arabicName: 'قنا',
    districts: {
      'Abu Tisht': { englishName: 'Abu Tisht', arabicName: 'أبو تشت' },
      'Al-Waqf': { englishName: 'Al-Waqf', arabicName: 'الوقف' },
      Armant: { englishName: 'Armant', arabicName: 'أرمنت' },
      Dishna: { englishName: 'Dishna', arabicName: 'ديشنا' },
      Farshut: { englishName: 'Farshut', arabicName: 'فرشوط' },
      Isna: { englishName: 'Isna', arabicName: 'إسنا' },
      'Nag Hammadi': { englishName: 'Nag Hammadi', arabicName: 'نجع حمادي' },
      Naqadah: { englishName: 'Naqadah', arabicName: 'نقادة' },
      Qift: { englishName: 'Qift', arabicName: 'قفط' },
      Qina: { englishName: 'Qina', arabicName: 'قنا' },
      'Qina City': { englishName: 'Qina City', arabicName: 'مدينة قنا' },
      Qus: { englishName: 'Qus', arabicName: 'قوص' },
    },
  },
  'North Sinai': {
    englishName: 'North Sinai',
    arabicName: 'شمال سيناء',
    districts: {
      'Al-Arish 1': { englishName: 'Al-Arish 1', arabicName: 'العريش ١' },
      'Al-Arish 2': { englishName: 'Al-Arish 2', arabicName: 'العريش ٢' },
      'Al-Arish 3': { englishName: 'Al-Arish 3', arabicName: 'العريش ٣' },
      'Al-Arish 4': { englishName: 'Al-Arish 4', arabicName: 'العريش ٤' },
      'Al-Hasanah': { englishName: 'Al-Hasanah', arabicName: 'الحسنة' },
      'Bir Al-Abd': { englishName: 'Bir Al-Abd', arabicName: 'بئر العبد' },
      Nakhl: { englishName: 'Nakhl', arabicName: 'النخل' },
      Rafah: { englishName: 'Rafah', arabicName: 'رفح' },
      'Sheikh Zuweid': {
        englishName: 'Sheikh Zuweid',
        arabicName: 'الشيخ زويد',
      },
      'Shurtet Al-Qasima': {
        englishName: 'Shurtet Al-Qasima',
        arabicName: 'شرطة القسيمة',
      },
      'Shurtet Rumana': {
        englishName: 'Shurtet Rumana',
        arabicName: 'شرطة رومانة',
      },
    },
  },
  Sohag: {
    englishName: 'Sohag',
    arabicName: 'سوهاج',
    districts: {
      Akhmim: { englishName: 'Akhmim', arabicName: 'أخميم' },
      Aserat: { englishName: 'Aserat', arabicName: 'أسيرات' },
      'Al-Baliyana': { englishName: 'Al-Baliyana', arabicName: 'البليانة' },
      'Al-Kawthar': { englishName: 'Al-Kawthar', arabicName: 'الكوثر' },
      'Al-Munsha': { englishName: 'Al-Munsha', arabicName: 'المنشا' },
      'Al-Maraghah': { englishName: 'Al-Maraghah', arabicName: 'المراغة' },
      'Dar Al-Salam': { englishName: 'Dar Al-Salam', arabicName: 'دار السلام' },
      Girga: { englishName: 'Girga', arabicName: 'جرجا' },
      'Juhaynah West': {
        englishName: 'Juhaynah West',
        arabicName: 'جهينة الغربية',
      },
      'New Akhmim': { englishName: 'New Akhmim', arabicName: 'أخميم الجديدة' },
      'New Sohag': { englishName: 'New Sohag', arabicName: 'سوهاج الجديدة' },
      Saqultah: { englishName: 'Saqultah', arabicName: 'سقلطة' },
      Sohag: { englishName: 'Sohag', arabicName: 'سوهاج' },
      'Sohag 2': { englishName: 'Sohag 2', arabicName: 'سوهاج ٢' },
      Tahta: { englishName: 'Tahta', arabicName: 'طهطا' },
      'Tahta City': { englishName: 'Tahta City', arabicName: 'مدينة طهطا' },
      Tima: { englishName: 'Tima', arabicName: 'تيماء' },
    },
  },
  Luxor: {
    englishName: 'Luxor',
    arabicName: 'الأقصر',
    districts: {
      Luxor: { englishName: 'Luxor', arabicName: 'الأقصر' },
      'Thebes Police Dept': {
        englishName: 'Thebes Police Dept',
        arabicName: 'مديرية أمن طيبة',
      },
    },
  },
  Suez: {
    englishName: 'Suez',
    arabicName: 'السويس',
    districts: {
      Attaka: { englishName: 'Attaka', arabicName: 'العتاقة' },
      Arbaeen: { englishName: 'Arbaeen', arabicName: 'الأربعين' },
      Ganayen: { englishName: 'Ganayen', arabicName: 'الجناين' },
      Faisal: { englishName: 'Faisal', arabicName: 'فيصل' },
      'Port Suez Police Dept': {
        englishName: 'Port Suez Police Dept',
        arabicName: 'مديرية أمن ميناء السويس',
      },
      Suez: { englishName: 'Suez', arabicName: 'السويس' },
    },
  },
  Sharqia: {
    englishName: 'Sharqia',
    arabicName: 'الشرقية',
    districts: {
      '10th of Ramadan City 1': {
        englishName: '10th of Ramadan City 1',
        arabicName: 'مدينة العاشر من رمضان 1',
      },
      '10th of Ramadan City 2': {
        englishName: '10th of Ramadan City 2',
        arabicName: 'مدينة العاشر من رمضان 2',
      },
      'Abu Hammad': { englishName: 'Abu Hammad', arabicName: 'أبو حماد' },
      'Abu Kebir': { englishName: 'Abu Kebir', arabicName: 'أبو كبير' },
      'Al-Husseiniya': { englishName: 'Al-Husseiniya', arabicName: 'الحسينية' },
      'Al-Ibrahimiya': {
        englishName: 'Al-Ibrahimiya',
        arabicName: 'الإبراهيمية',
      },
      'Al-Qanayat': { englishName: 'Al-Qanayat', arabicName: 'القنايات' },
      'Al-Qurayn': { englishName: 'Al-Qurayn', arabicName: 'القرين' },
      'Awlad Saqr': { englishName: 'Awlad Saqr', arabicName: 'أولاد صقر' },
      Bilbeis: { englishName: 'Bilbeis', arabicName: 'بلبيس' },
      'Diyarb Negm': { englishName: 'Diyarb Negm', arabicName: 'ديرب نجم' },
      Faqus: { englishName: 'Faqus', arabicName: 'فاقوس' },
      Hihya: { englishName: 'Hihya', arabicName: 'ههيا' },
      'Kafr Saqr': { englishName: 'Kafr Saqr', arabicName: 'كفر صقر' },
      'Mashtool Al-Souk': {
        englishName: 'Mashtool Al-Souk',
        arabicName: 'مشتول السوق',
      },
      'Minya Al-Qamh': {
        englishName: 'Minya Al-Qamh',
        arabicName: 'منيا القمح',
      },
      'New Salhiyah': {
        englishName: 'New Salhiyah',
        arabicName: 'الصالحية الجديدة',
      },
      Zaqaziq: { englishName: 'Zaqaziq', arabicName: 'الزقازيق' },
      'Zaqaziq 1': { englishName: 'Zaqaziq 1', arabicName: 'الزقازيق 1' },
      'Zaqaziq 2': { englishName: 'Zaqaziq 2', arabicName: 'الزقازيق 2' },
    },
  },
  Aswan: {
    englishName: 'Aswan',
    arabicName: 'أسوان',
    districts: {
      'Abu Simbel': { englishName: 'Abu Simbel', arabicName: 'أبو سمبل' },
      Aswan: { englishName: 'Aswan', arabicName: 'أسوان' },
      'Aswan City': { englishName: 'Aswan City', arabicName: 'مدينة أسوان' },
      Daraw: { englishName: 'Daraw', arabicName: 'دراو' },
      Edfu: { englishName: 'Edfu', arabicName: 'إدفو' },
      'Kawm Umbu': { englishName: 'Kawm Umbu', arabicName: 'كوم أمبو' },
      Nasr: { englishName: 'Nasr', arabicName: 'نصر' },
      'New Tushka': { englishName: 'New Tushka', arabicName: 'تشكا الجديدة' },
    },
  },
  Asyut: {
    englishName: 'Asyut',
    arabicName: 'أسيوط',
    districts: {
      Abnub: { englishName: 'Abnub', arabicName: 'أبنوب' },
      'Abu Tig': { englishName: 'Abu Tig', arabicName: 'أبو تيج' },
      'Al-Badari': { englishName: 'Al-Badari', arabicName: 'البداري' },
      'Al-Fateh': { englishName: 'Al-Fateh', arabicName: 'الفاتح' },
      'Al-Ghanayim': { englishName: 'Al-Ghanayim', arabicName: 'الغنايم' },
      'Al-Qusiyah': { englishName: 'Al-Qusiyah', arabicName: 'القوصية' },
      Asyut: { englishName: 'Asyut', arabicName: 'أسيوط' },
      'Asyut 1': { englishName: 'Asyut 1', arabicName: 'أسيوط 1' },
      'Asyut 2': { englishName: 'Asyut 2', arabicName: 'أسيوط 2' },
      Dairut: { englishName: 'Dairut', arabicName: 'ديروط' },
      Manfalut: { englishName: 'Manfalut', arabicName: 'منفلوط' },
      'New Asyut': { englishName: 'New Asyut', arabicName: 'أسيوط الجديدة' },
      'Sahel Selim': { englishName: 'Sahel Selim', arabicName: 'ساحل سليم' },
      Sidfa: { englishName: 'Sidfa', arabicName: 'سدفا' },
    },
  },
  'Beni Suef': {
    englishName: 'Beni Suef',
    arabicName: 'بني سويف',
    districts: {
      'Al-Fashn': { englishName: 'Al-Fashn', arabicName: 'الفشن' },
      'Al-Wasta': { englishName: 'Al-Wasta', arabicName: 'الوسطى' },
      'Beni Suef': { englishName: 'Beni Suef', arabicName: 'بني سويف' },
      'Beni Suef City': {
        englishName: 'Beni Suef City',
        arabicName: 'مدينة بني سويف',
      },
      Biba: { englishName: 'Biba', arabicName: 'بيبا' },
      Ihnasiya: { englishName: 'Ihnasiya', arabicName: 'إهناسيا' },
      Nasser: { englishName: 'Nasser', arabicName: 'ناصر' },
      'Sumusta Al-Waqf': {
        englishName: 'Sumusta Al-Waqf',
        arabicName: 'سموسة الوقف',
      },
    },
  },
  'Port Said': {
    englishName: 'Port Said',
    arabicName: 'بور سعيد',
    districts: {
      'Al-Dawahy': { englishName: 'Al-Dawahy', arabicName: 'الدواحي' },
      'Al-Arab': { englishName: 'Al-Arab', arabicName: 'العرب' },
      'Al-Janoub': { englishName: 'Al-Janoub', arabicName: 'الجنوب' },
      'Al-Janoub 2': { englishName: 'Al-Janoub 2', arabicName: 'الجنوب 2' },
      'Al-Manakh': { englishName: 'Al-Manakh', arabicName: 'المناخ' },
      'Al-Manasrah': { englishName: 'Al-Manasrah', arabicName: 'المناصرة' },
      'Al-Sharq': { englishName: 'Al-Sharq', arabicName: 'الشرق' },
      'Al-Zohur': { englishName: 'Al-Zohur', arabicName: 'الزهور' },
      'Mubarak East': { englishName: 'Mubarak East', arabicName: 'مبارك شرق' },
      'Port Fuad': { englishName: 'Port Fuad', arabicName: 'بور فؤاد' },
      'Port Fuad 2': { englishName: 'Port Fuad 2', arabicName: 'بور فؤاد 2' },
      'Port Said Police Dept': {
        englishName: 'Port Said Police Dept',
        arabicName: 'مركز شرطة بور سعيد',
      },
    },
  },
  Damietta: {
    englishName: 'Damietta',
    arabicName: 'دمياط',
    districts: {
      Damietta: { englishName: 'Damietta', arabicName: 'دمياط' },
      'Damietta 1': { englishName: 'Damietta 1', arabicName: 'دمياط 1' },
      'Damietta 2': { englishName: 'Damietta 2', arabicName: 'دمياط 2' },
      Faraskur: { englishName: 'Faraskur', arabicName: 'فارسكور' },
      'Kafr Saad': { englishName: 'Kafr Saad', arabicName: 'كفر سعد' },
      'New Damietta': {
        englishName: 'New Damietta',
        arabicName: 'الدمياط الجديدة',
      },
      'Port of Damietta Police Dept': {
        englishName: 'Port of Damietta Police Dept',
        arabicName: 'مركز شرطة ميناء دمياط',
      },
      Zarqa: { englishName: 'Zarqa', arabicName: 'زرقا' },
    },
  },
  'South Sinai': {
    englishName: 'South Sinai',
    arabicName: 'جنوب سيناء',
    districts: {
      'Abu Radis': { englishName: 'Abu Radis', arabicName: 'أبو رديس' },
      'Abu Zenima': { englishName: 'Abu Zenima', arabicName: 'أبو زنيمة' },
      'Al-Tur': { englishName: 'Al-Tur', arabicName: 'الطور' },
      Dahab: { englishName: 'Dahab', arabicName: 'دهب' },
      Nuweiba: { englishName: 'Nuweiba', arabicName: 'نويبع' },
      'Ras Sedr': { englishName: 'Ras Sedr', arabicName: 'رأس سدر' },
      'Saint Catherine': {
        englishName: 'Saint Catherine',
        arabicName: 'سانت كاترين',
      },
      'Sharm Sheikh': {
        englishName: 'Sharm Sheikh',
        arabicName: 'شرم الشيخ',
      },
      Taba: { englishName: 'Taba', arabicName: 'طابا' },
    },
  },
  'Kafr Al-Sheikh': {
    englishName: 'Kafr Al-Sheikh',
    arabicName: 'كفر الشيخ',
    districts: {
      'Al-Burulus': { englishName: 'Al-Burulus', arabicName: 'البرلس' },
      'Al-Hamool': { englishName: 'Al-Hamool', arabicName: 'الحمول' },
      'Al-Reyad': { englishName: 'Al-Reyad', arabicName: 'الرياض' },
      Bila: { englishName: 'Bila', arabicName: 'بيلة' },
      Desouk: { englishName: 'Desouk', arabicName: 'دسوق' },
      Fuwah: { englishName: 'Fuwah', arabicName: 'فوة' },
      'Kafr Al-Sheikh': {
        englishName: 'Kafr Al-Sheikh',
        arabicName: 'كفر الشيخ',
      },
      Metoubes: { englishName: 'Metoubes', arabicName: 'متوبس' },
      Qallin: { englishName: 'Qallin', arabicName: 'قلين' },
      'Sidi Salem': { englishName: 'Sidi Salem', arabicName: 'سيدي سالم' },
    },
  },
};
