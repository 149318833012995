import { Auth } from 'aws-amplify';

export const addressEndpoint: string =
  'https://prod-aw.api.blassa.io/addresses';
export const addressRequestJobEndpoint: string =
  'https://prod-bi.api.blassa.io/address-request-jobs';

export const signUp = async (phone: string, password: string) => {
  const user = await Auth.signUp({
    attributes: {
      given_name: 'Guest',
      family_name: 'Guest',
    },
    password,
    username: phone,
    autoSignIn: {
      enabled: true,
    },
  });
  return user;
};

// @ts-ignore @typescript-eslint/no-unused-vars
export const signIn = async (phone: string, password: string) => {
  return {};
};

export const verifyOTP = async (phone: string, code: string) => {
  await Auth.confirmSignUp(phone, code);
  return true;
};

export const updateProfileInfo = async (
  firstname: string,
  lastname: string,
) => {
  const user = await Auth.currentAuthenticatedUser();
  await Auth.updateUserAttributes(user, {
    given_name: firstname,
    family_name: lastname,
  });
  return true;
};
