import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import AccountCreated from '../pages/AccountCreated';
import CreateAccount from '../pages/CreateAccount';
import AddressFields from '../pages/CreateAddress/address-fields';
import AddressSubmited from '../pages/CreateAddress/address-submited';
import DeliveryWindow from '../pages/CreateAddress/delivery-window';
import RefineLocation from '../pages/CreateAddress/refine-location';
import Profile from '../pages/Profile';
import ProfileEdit from '../pages/ProfileEdit';
import VerifyPhone from '../pages/VerifyPhone';
import StreetSelection from '../pages/CreateAddress/street-selection';

const router = createBrowserRouter([
  {
    path: '/:integrationId',
    element: <RefineLocation />,
  },
  {
    path: '/:integrationId/refine-location',
    element: <RefineLocation />,
  },
  {
    path: '/:integrationId/address-details',
    element: <AddressFields />,
  },
  {
    path: '/:integrationId/street-selection',
    element: <StreetSelection />,
  },
  {
    path: '/:integrationId/delivery-window',
    element: <DeliveryWindow />,
  },
  {
    path: '/address-saved',
    element: <AddressSubmited />,
  },
  {
    path: '/signup',
    element: <CreateAccount />,
  },
  {
    path: '/',
    element: <CreateAccount />,
  },
  {
    path: '/verify-account',
    element: <VerifyPhone />,
  },
  {
    path: '/account-created',
    element: <AccountCreated />,
  },
  {
    path: '/profile',
    element: <Profile />,
  },
  {
    path: '/profile/edit',
    element: <ProfileEdit />,
  },
]);

function AppNavigator() {
  return <RouterProvider router={router} />;
}

export default AppNavigator;
