import './style.scss';
import ReactGA from 'react-ga4';
import { useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { signIn } from '../../utils/API';
import { useNavigate } from 'react-router-dom';
// import useAnalytics from '../../hooks/useAnalytics';

export default function AccountCreated() {
  const navigate = useNavigate();
  // const { eventTracker } = useAnalytics('Account Creation');

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: window.location.pathname,
      title: 'Account Created',
    });
  }, []);

  async function submit() {
    const phone = localStorage.getItem('phone');
    const password = localStorage.getItem('tempPass');
    try {
      const user = await signIn(phone ?? '', password ?? '');
      if (user) {
        navigate('/profile');
      } else {
        alert('error logging in');
        // todo handle error here
      }
    } catch (err) {
      alert('Something went wrong');
    }
  }
  return (
    <div className="account-created">
      <img src="/assets/img/acount-created.png" alt="account-created" />
      <h1>Log in on the Blassa app to make the most of your deliveries</h1>
      <h2>
        Your account has been activated. Download the Blassa app and Log in to
        and start the journey to the convenience of receiving deliveries to your
        doorstep.
      </h2>
      <Button variant="primary" size="lg" onClick={submit}>
        Log in on the Blassa App
      </Button>
    </div>
  );
}
