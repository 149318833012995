import { useEffect } from 'react';
import { Button, Container, Form } from 'react-bootstrap';
import './style.scss';
import useAnalytics from '../../hooks/useAnalytics';
import ReactGA from 'react-ga4';

function AddressSubmited() {
  const { eventTracker } = useAnalytics('Address Verification');

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: window.location.pathname,
      title: 'Address Submitted',
    });
  }, []);

  return (
    <Container className="address-submited">
      <img
        src="/assets/img/tick-success.png"
        className="success-icon"
        alt="success-icon"
      />
      <h1>Thank you for submitting your address and delivery details!</h1>
      <h3>Your information has been securely saved.</h3>
      <div className="content">
        <h2>Want to reuse this address in the future?</h2>
        <h3>
          Make the most out of Blassa by creating an account. Here’s what you
          get:
        </h3>
        <div className="section">
          <img src="/assets/img/one-click.png" alt="one-click-icon" />
          <span>
            One-click address checkout in all of your online shopping websites
          </span>
        </div>
        <div className="section">
          <img src="/assets/img/set-timer.png" alt="timer-icon" />
          <span>Set preferred delivery time windows</span>
        </div>
        <div className="section">
          <img src="/assets/img/change-address.png" alt="change-address-icon" />
          <span>
            Change your address in one place for all online shopping accounts
          </span>
        </div>
      </div>
      <Form style={{ padding: 20, width: '100%' }}>
        <div className="d-grid gap-2">
          <Button
            variant="primary"
            size="lg"
            onClick={() => {
              eventTracker({
                action: 'create_account_button',
                label: 'clicked',
              });
              window.open('https://urlgeni.us/DBhUSms', '_blank');
            }}>
            Create a Blassa account
          </Button>
        </div>
      </Form>
    </Container>
  );
}

export default AddressSubmited;
