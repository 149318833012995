import axios, { AxiosRequestConfig } from 'axios';

const PROD_BASE_URL = 'https://prod-bi.api.blassa.io';

export async function reverseGeocodeLocation(params: {
  lat: any;
  lng: any;
}): Promise<any> {
  const apiUrl: string = `${PROD_BASE_URL}/reverse-geocode-location`;
  const apiKey: string = 'qhRUcY1Bje1FOysxVe1cw5CuEyqGFEaC6A0aNh2t';

  const requestBody: any = {
    geoLocation: {
      type: 'POINT',
      coordinates: {
        latitude: params.lat,
        longitude: params.lng,
      },
    },
  };

  const config: AxiosRequestConfig = {
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': apiKey,
    },
  };

  const response = await axios.post(apiUrl, requestBody, config);
  return response.data;
}
