import ReactGA from 'react-ga4';
import { useEffect } from 'react';
import AppNavigator from './navigations';
import TopNavBar from './components/TopNavBar';
import { useTranslation } from 'react-i18next';

ReactGA.initialize([
  {
    trackingId: 'G-YR6K6NQKV7',
  },
]);

function App() {
  const { i18n } = useTranslation();
  useEffect(() => {
    document.body.dir = i18n.dir?.();
  }, [i18n]);
  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: window.location.pathname,
      title: 'Verify Address',
    });
  }, []);

  return (
    <>
      <TopNavBar />
      <AppNavigator />
    </>
  );
}

export default App;
