import { t } from 'i18next';
import ReactGA from 'react-ga4';
import ReactSelect from 'react-select';
import { toast, ToastContainer } from 'react-toastify';
import { useEffect, useState } from 'react';
import useAnalytics from '../../hooks/useAnalytics';
import CustomForm from '../../components/CustomForm';
import { Button, Col, Form, Row } from 'react-bootstrap';
import useIntegrations from '../../hooks/useIntegrations';
import CountrySelector from '../../components/CountrySelector';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  ICountry,
  LEVEL_1_DIVISION_NAMES_PER_COUNTRY,
  supportedCountries,
} from '../../components/CountrySelector/countries';
// @ts-ignore
import LoadingOverlay from 'react-loading-overlay';

import './style.scss';
import { mixpanel } from '../../utils/mixpanel';

// @typescript-eslint/no-unused-vars

function AddressFields() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [addressFields, setAddressFields] = useState<any>(
    state.addressRequestJob.deliveryDetails.addressFields,
  );
  const [countryCode, setCountryCode] = useState<string>(
    state.addressRequestJob.deliveryDetails.countryCode,
  );
  const [geoLocation, setGeoLocation] = useState<any>(
    state.addressRequestJob.deliveryDetails.geoLocation,
  );
  const [firstName, setFirstName] = useState<string>(
    state.addressRequestJob.customerDetails.firstName,
  );
  const [lastName, setLastName] = useState<string>(
    state.addressRequestJob.customerDetails.lastName,
  );
  const [email, setEmail] = useState<string>(
    state.addressRequestJob.customerDetails.email,
  );
  const [addressType, setAddressType] = useState<
    'House' | 'Apartment' | 'Office'
  >(state.addressRequestJob.deliveryDetails.type);
  const [toc, setToc] = useState<Boolean>(true);
  const [receiveUpdates, setReceiveUpdates] = useState<Boolean>(true);
  const { updateIntegrationId } = useIntegrations();
  const { eventTracker } = useAnalytics('Address Verification');

  async function submit() {
    setLoading(true);
    eventTracker({ action: 'address_fields_submit', label: 'clicked' });
    const integrationId = localStorage.getItem('integrationId');
    if (!integrationId) {
      toast.error('Invalid request ID', {
        position: toast.POSITION.TOP_RIGHT,
      });
      eventTracker({
        action: 'address_fields_submit',
        label: 'failure_invalid_id',
      });
      return;
    }

    try {
      const updateAddressRequestJobResponse = await updateIntegrationId(
        integrationId as string,
        {
          type: addressType,
          countryCode,
          addressFields,
          geoLocation,
        },
      );
      if (updateAddressRequestJobResponse.data) {
        eventTracker({ action: 'address_fields_submit', label: 'success' });
        navigate(`/${state.addressRequestJob.id}/street-selection`, {
          state: {
            addressRequestJob: updateAddressRequestJobResponse.data,
            streetSuggestions: state.streetSuggestions,
          },
        });
        mixpanel.track('Update Address Fields', {
          status: 'success',
          integrationId: state.addressRequestJob.id,
          countryCode: countryCode,
        });
      }
    } catch (error) {
      // @ts-ignore
      toast.error(error.response?.data?.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      eventTracker({ action: 'address_fields_submit', label: 'failure' });
      mixpanel.track('Update Address Fields', {
        status: 'failure',
        integrationId: state.addressRequestJob.id,
      });
    }
    setLoading(false);
  }

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: window.location.pathname,
      title: 'Address Fields',
    });
  }, []);

  const requiredFields =
    supportedCountries[countryCode].addFields?.[addressType]?.filter(
      (field) => field.required,
    ) ?? [];
  const isValid =
    firstName.length > 0 &&
    lastName.length > 0 &&
    // /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email) &&
    (
      requiredFields
        .map(
          (field) =>
            addressFields[field.name] && addressFields[field.name].length > 0,
        )
        .filter((value) => value) ?? []
    ).length === requiredFields.length &&
    toc;

  return (
    <LoadingOverlay active={loading} spinner text="Loading your content...">
      <div className="create-address">
        <h1>
          Verify your delivery details for your order from{' '}
          {state.addressRequestJob.orderDetails.orderSource}
        </h1>
        <Row>
          <Col>
            <p>
              Order{' '}
              <span>#{state.addressRequestJob.orderDetails.orderNumber}</span>
            </p>
          </Col>
        </Row>
        <Form>
          <Form.Group className="mb-3" controlId="name">
            <Form.Label>{t('FIRST_NAME')}*</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter your first name"
              onChange={(e) => setFirstName(e.target.value.trim())}
              value={firstName}
            />
            <Form.Label>{t('LAST_NAME')}*</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter your last name"
              onChange={(e) => setLastName(e.target.value)}
              value={lastName}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="email">
            <Form.Label>E-mail</Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter your email address"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="country">
            <Form.Label>Country*</Form.Label>
            <CountrySelector
              isDisabled
              key={countryCode}
              countryCode={countryCode}
              onChange={(country: ICountry) => setCountryCode(country.isoCode)}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="country">
            <Form.Label>Address Type*</Form.Label>
            <ReactSelect
              value={{ value: addressType, label: addressType as string }}
              options={[
                { label: t('APARTMENT') as string, value: 'Apartment' },
                { label: t('HOUSE') as string, value: 'House' },
                { label: t('OFFICE') as string, value: 'Office' },
              ]}
              onChange={(item) => {
                if (item?.value) {
                  setAddressType(
                    item.value as 'House' | 'Apartment' | 'Office',
                  );
                }
              }}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="country">
            <CustomForm
              addressFields={addressFields}
              countryCode={countryCode}
              config={supportedCountries[countryCode].addFields?.[addressType]}
              onChange={(fieldName: string, fieldValue: string) => {
                const countryKey =
                  countryCode.toUpperCase() as keyof typeof LEVEL_1_DIVISION_NAMES_PER_COUNTRY;
                if (
                  fieldName === LEVEL_1_DIVISION_NAMES_PER_COUNTRY[countryKey]
                ) {
                  setAddressFields({
                    [fieldName]: fieldValue,
                  });
                } else {
                  setAddressFields({
                    ...addressFields,
                    [fieldName]: fieldValue,
                  });
                }
              }}
            />
          </Form.Group>
          <Form.Check
            type="checkbox"
            id="TOC"
            label={
              <a
                href="https://www.blassa.io/privacy-policy"
                target="_blank"
                rel="noopener noreferrer">
                I agree to Blassa's Terms & Conditions.
              </a>
            }
            defaultChecked
            onChange={(e) => {
              eventTracker({
                action: 'address_fields_interaction',
                label: 'toc_checkbox',
              });
              setToc(e.target.checked);
            }}
          />
          <Form.Check
            type="checkbox"
            id="I want to receive updates about my orders from Blassa."
            label="I want to receive updates about my orders from Blassa."
            defaultChecked
            onChange={(e) => {
              eventTracker({
                action: 'address_fields_interaction',
                label: 'updates_checkbox',
              });
              setReceiveUpdates(e.target.checked);
            }}
          />
          <div className="d-grid gap-2">
            <Button
              disabled={!isValid}
              variant={isValid ? 'primary' : 'disabled'}
              size="lg"
              onClick={submit}>
              Confirm details
            </Button>
          </div>
        </Form>
      </div>
      <ToastContainer />
    </LoadingOverlay>
  );
}

export default AddressFields;
