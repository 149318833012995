import { useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { Auth } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';
import './style.scss';
import { updateProfileInfo } from '../../utils/API';

function ProfileEdit() {
  const [user, setUser] = useState<any>(undefined);
  const [firstname, setFirstname] = useState<string>('');
  const [lastname, setLastname] = useState<string>('');
  const navigate = useNavigate();

  async function loadUser() {
    const currentAuthenticatedUser = await Auth.currentAuthenticatedUser();
    setUser(currentAuthenticatedUser);
    setFirstname(currentAuthenticatedUser?.attributes?.given_name ?? '');
    setLastname(currentAuthenticatedUser?.attributes?.family_name ?? '');
  }

  async function submit() {
    try {
      const updated = await updateProfileInfo(firstname, lastname);
      if (updated) {
        navigate('/profile');
      } else {
        alert('cannot update user');
      }
    } catch (err: any) {
      alert(err.message);
    }
  }

  useEffect(() => {
    loadUser();
  }, []);

  return (
    <div className="edit-account">
      <h1>Enter your full name</h1>
      <Form>
        <Form.Group className="mb-3" controlId="given_name">
          <Form.Label>First name</Form.Label>
          <Form.Control
            id="given_name"
            type="text"
            placeholder={user?.attributes?.given_name}
            onChange={(e) => setFirstname(e.target.value)}
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="family_name">
          <Form.Label>Last name</Form.Label>
          <Form.Control
            id="family_name"
            type="text"
            placeholder={user?.attributes?.family_name}
            onChange={(e) => setLastname(e.target.value)}
          />
        </Form.Group>
      </Form>

      <div className="d-grid bottom-container">
        <Button variant="primary" size="lg" onClick={submit}>
          Continue
        </Button>
      </div>
    </div>
  );
}

export default ProfileEdit;
