import axios, { AxiosError } from 'axios';
import { useState } from 'react';
import { addressRequestJobEndpoint } from '../utils/API';
import { toast } from 'react-toastify';
import { mixpanel } from '../utils/mixpanel';

export interface OrderDetails {
  orderSource: string;
  orderNumber: string;
  description: string;
}

export interface DeliveryDetails {
  type: string;
  countryCode: string;
  addressFields?: any;
  geoLocation?: {
    type: string;
    coordinates: {
      latitude: number;
      longitude: number;
    };
  };
  deliveryWindows?: {
    startAt: string;
    endAt: string;
  }[];
}

export interface CustomerDetails {
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
}

export default function useIntegrations() {
  const [loading, setLoading] = useState<boolean>(false);
  const [isIntegrationIdExpired, setIsIntegrationIdExpired] =
    useState<boolean>(false);
  const [orderDetails, setOrderDetails] = useState<OrderDetails | undefined>(
    undefined,
  );
  const [customerDetails, setCustomerDetails] = useState<
    CustomerDetails | undefined
  >(undefined);
  const [deliveryDetails, setDeliveryDetails] = useState<
    DeliveryDetails | undefined
  >(undefined);

  const getIntegration = async (integrationId: string) => {
    setLoading(true);
    localStorage.setItem('integrationId', integrationId);
    try {
      const response = await axios.get(
        `${addressRequestJobEndpoint}/${integrationId}`,
      );
      console.log('GetAddressRequesyJob response', {
        response,
      });
      if (response.data) {
        console.log('Successfully called GetAddressRequesyJob', {
          integrationId,
        });
        const retrievedOrderDetails = response.data.orderDetails;
        if (retrievedOrderDetails) {
          setOrderDetails(retrievedOrderDetails);
          localStorage.setItem(
            'orderDetails',
            JSON.stringify(retrievedOrderDetails),
          );
        }
        const retrievedCustomerDetails = response.data.customerDetails;
        if (retrievedCustomerDetails) {
          setCustomerDetails(retrievedCustomerDetails);
        }
        const retrievedDeliveryDetails = response.data.deliveryDetails;
        if (retrievedDeliveryDetails) {
          setDeliveryDetails(retrievedDeliveryDetails);
          localStorage.setItem(
            'deliveryDetails',
            JSON.stringify(retrievedDeliveryDetails),
          );
          mixpanel.track('Get Address Request Job', {
            status: 'success',
            integrationId: integrationId,
          });
        }
        setLoading(false);
      } else {
        setLoading(false);
        toast.error('Could not retrieve request details', {
          position: toast.POSITION.TOP_RIGHT,
        });
        mixpanel.track('Get Address Request Job', {
          status: 'failure',
          integrationId: integrationId,
        });
      }
    } catch (err) {
      console.log('Failed to call GetAddressRequesyJob', {
        integrationId,
        err,
      });
      setLoading(false);
      setIsIntegrationIdExpired(true);
      toast.error('Could not retrieve request details', {
        position: toast.POSITION.TOP_RIGHT,
      });
      mixpanel.track('Get Address Request Job', {
        status: 'failure',
        integrationId: integrationId,
      });
    }
  };

  async function getOrderDetailsLocally() {
    const data = localStorage.getItem('orderDetails');
    if (data) setOrderDetails(JSON.parse(data));
  }

  async function getDeliveryDetailsLocally() {
    const data = localStorage.getItem('deliveryDetails');
    if (data) setDeliveryDetails(JSON.parse(data));
  }

  const updateIntegrationId = async (
    integrationId: string,
    data: DeliveryDetails,
  ) => {
    setLoading(true);
    try {
      const response = await axios.patch(
        `${addressRequestJobEndpoint}/${integrationId}`,
        { deliveryDetails: data },
      );
      setLoading(false);
      return response;
    } catch (err) {
      setLoading(false);
      const error = err as AxiosError;
      throw error;
    }
  };
  return {
    loading,
    isIntegrationIdExpired,
    getIntegration,
    updateIntegrationId,
    orderDetails,
    deliveryDetails,
    customerDetails,
    getOrderDetailsLocally,
    getDeliveryDetailsLocally,
  };
}
