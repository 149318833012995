import ReactSelect from 'react-select';
import { Col, Form, InputGroup } from 'react-bootstrap';
import { ICountry, supportedCountries } from './countries';
import './style.scss';

interface ICountrySelectorProps {
  isPhoneInput?: boolean;
  onChange: (country: ICountry) => void;
  countryCode: string;
  onPhoneChange?: (phone: string) => void;
  isDisabled: boolean;
}

function CountrySelector({
  onChange,
  countryCode,
  isPhoneInput,
  onPhoneChange,
  isDisabled,
}: ICountrySelectorProps) {
  const country = supportedCountries[countryCode];
  return isPhoneInput ? (
    <InputGroup className="mb-'">
      <InputGroup.Text id="phone">
        <ReactSelect
          isDisabled={isDisabled}
          value={{
            label: `${country.emoji} +${country.phone}`,
            value: country,
          }}
          options={Object.keys(supportedCountries).map(
            (countryKey: string) => ({
              label: `${supportedCountries[countryKey].emoji} +${supportedCountries[countryKey].phone}`,
              value: supportedCountries[countryKey],
            }),
          )}
          onChange={(item) => {
            if (item?.value) onChange(item.value);
          }}
        />
      </InputGroup.Text>
      <Form.Control
        onChange={(e) => onPhoneChange?.(e.target.value)}
        id="phone-input"
        autoComplete="false"
        name="phone"
        aria-describedby="phone"
      />
    </InputGroup>
  ) : (
    <Col>
      <ReactSelect
        isDisabled={isDisabled}
        value={{ label: `${country.emoji} ${country.name}`, value: country }}
        options={Object.keys(supportedCountries).map((countryKey: string) => ({
          label: `${supportedCountries[countryKey].emoji} ${supportedCountries[countryKey].name}`,
          value: supportedCountries[countryKey],
        }))}
        onChange={(item) => {
          if (item?.value) onChange(item.value);
        }}
      />
    </Col>
  );
}

export default CountrySelector;
