import { useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import ReactSelect from 'react-select';
// @ts-ignore
import LoadingOverlay from 'react-loading-overlay';
import { toast, ToastContainer } from 'react-toastify';
import useIntegrations from '../../hooks/useIntegrations';
import ReactGA from 'react-ga4';
import useAnalytics from '../../hooks/useAnalytics';
import { useLocation, useNavigate } from 'react-router-dom';
import { mixpanel } from '../../utils/mixpanel';

import './style.scss';
import 'react-toastify/dist/ReactToastify.css';

const deliveryTimes = [
  {
    value: 1,
    text: '08:30 - 12:30',
    icon: (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M12 2C10.0222 2 8.08879 2.58649 6.4443 3.6853C4.79981 4.78412 3.51809 6.3459 2.76121 8.17317C2.00433 10.0004 1.8063 12.0111 2.19215 13.9509C2.578 15.8907 3.53041 17.6725 4.92894 19.0711C6.32746 20.4696 8.10929 21.422 10.0491 21.8079C11.9889 22.1937 13.9996 21.9957 15.8268 21.2388C17.6541 20.4819 19.2159 19.2002 20.3147 17.5557C21.4135 15.9112 22 13.9778 22 12C22 10.6868 21.7413 9.38642 21.2388 8.17317C20.7363 6.95991 19.9997 5.85752 19.0711 4.92893C18.1425 4.00035 17.0401 3.26375 15.8268 2.7612C14.6136 2.25866 13.3132 2 12 2ZM12 20C10.4178 20 8.87104 19.5308 7.55544 18.6518C6.23985 17.7727 5.21447 16.5233 4.60897 15.0615C4.00347 13.5997 3.84504 11.9911 4.15372 10.4393C4.4624 8.88743 5.22433 7.46197 6.34315 6.34315C7.46197 5.22433 8.88743 4.4624 10.4393 4.15372C11.9911 3.84504 13.5997 4.00346 15.0615 4.60896C16.5233 5.21447 17.7727 6.23984 18.6518 7.55544C19.5308 8.87103 20 10.4177 20 12C20 14.1217 19.1572 16.1566 17.6569 17.6569C16.1566 19.1571 14.1217 20 12 20ZM13 11.93V7C13 6.73478 12.8946 6.48043 12.7071 6.29289C12.5196 6.10536 12.2652 6 12 6C11.7348 6 11.4804 6.10536 11.2929 6.29289C11.1054 6.48043 11 6.73478 11 7V12C11 12 11 12.07 11 12.11C10.9754 12.3133 11.0138 12.5192 11.11 12.7L12.61 15.3C12.7426 15.5307 12.9615 15.6993 13.2184 15.7687C13.4753 15.8381 13.7493 15.8026 13.98 15.67C14.2107 15.5374 14.3794 15.3186 14.4487 15.0616C14.5181 14.8047 14.4826 14.5307 14.35 14.3L13 11.93Z"
          fill="black"
        />
      </svg>
    ),
  },
  {
    value: 2,
    text: '12:30 - 16:30',
    icon: (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M12 2C10.0222 2 8.08879 2.58649 6.4443 3.6853C4.79981 4.78412 3.51809 6.3459 2.76121 8.17317C2.00433 10.0004 1.8063 12.0111 2.19215 13.9509C2.578 15.8907 3.53041 17.6725 4.92894 19.0711C6.32746 20.4696 8.10929 21.422 10.0491 21.8079C11.9889 22.1937 13.9996 21.9957 15.8268 21.2388C17.6541 20.4819 19.2159 19.2002 20.3147 17.5557C21.4135 15.9112 22 13.9778 22 12C22 10.6868 21.7413 9.38642 21.2388 8.17317C20.7363 6.95991 19.9997 5.85752 19.0711 4.92893C18.1425 4.00035 17.0401 3.26375 15.8268 2.7612C14.6136 2.25866 13.3132 2 12 2ZM12 20C10.4178 20 8.87104 19.5308 7.55544 18.6518C6.23985 17.7727 5.21447 16.5233 4.60897 15.0615C4.00347 13.5997 3.84504 11.9911 4.15372 10.4393C4.4624 8.88743 5.22433 7.46197 6.34315 6.34315C7.46197 5.22433 8.88743 4.4624 10.4393 4.15372C11.9911 3.84504 13.5997 4.00346 15.0615 4.60896C16.5233 5.21447 17.7727 6.23984 18.6518 7.55544C19.5308 8.87103 20 10.4177 20 12C20 14.1217 19.1572 16.1566 17.6569 17.6569C16.1566 19.1571 14.1217 20 12 20ZM13 11.93V7C13 6.73478 12.8946 6.48043 12.7071 6.29289C12.5196 6.10536 12.2652 6 12 6C11.7348 6 11.4804 6.10536 11.2929 6.29289C11.1054 6.48043 11 6.73478 11 7V12C11 12 11 12.07 11 12.11C10.9754 12.3133 11.0138 12.5192 11.11 12.7L12.61 15.3C12.7426 15.5307 12.9615 15.6993 13.2184 15.7687C13.4753 15.8381 13.7493 15.8026 13.98 15.67C14.2107 15.5374 14.3794 15.3186 14.4487 15.0616C14.5181 14.8047 14.4826 14.5307 14.35 14.3L13 11.93Z"
          fill="black"
        />
      </svg>
    ),
  },
  {
    value: 3,
    text: '16:30 - 20:30',
    icon: (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M12 2C10.0222 2 8.08879 2.58649 6.4443 3.6853C4.79981 4.78412 3.51809 6.3459 2.76121 8.17317C2.00433 10.0004 1.8063 12.0111 2.19215 13.9509C2.578 15.8907 3.53041 17.6725 4.92894 19.0711C6.32746 20.4696 8.10929 21.422 10.0491 21.8079C11.9889 22.1937 13.9996 21.9957 15.8268 21.2388C17.6541 20.4819 19.2159 19.2002 20.3147 17.5557C21.4135 15.9112 22 13.9778 22 12C22 10.6868 21.7413 9.38642 21.2388 8.17317C20.7363 6.95991 19.9997 5.85752 19.0711 4.92893C18.1425 4.00035 17.0401 3.26375 15.8268 2.7612C14.6136 2.25866 13.3132 2 12 2ZM12 20C10.4178 20 8.87104 19.5308 7.55544 18.6518C6.23985 17.7727 5.21447 16.5233 4.60897 15.0615C4.00347 13.5997 3.84504 11.9911 4.15372 10.4393C4.4624 8.88743 5.22433 7.46197 6.34315 6.34315C7.46197 5.22433 8.88743 4.4624 10.4393 4.15372C11.9911 3.84504 13.5997 4.00346 15.0615 4.60896C16.5233 5.21447 17.7727 6.23984 18.6518 7.55544C19.5308 8.87103 20 10.4177 20 12C20 14.1217 19.1572 16.1566 17.6569 17.6569C16.1566 19.1571 14.1217 20 12 20ZM13 11.93V7C13 6.73478 12.8946 6.48043 12.7071 6.29289C12.5196 6.10536 12.2652 6 12 6C11.7348 6 11.4804 6.10536 11.2929 6.29289C11.1054 6.48043 11 6.73478 11 7V12C11 12 11 12.07 11 12.11C10.9754 12.3133 11.0138 12.5192 11.11 12.7L12.61 15.3C12.7426 15.5307 12.9615 15.6993 13.2184 15.7687C13.4753 15.8381 13.7493 15.8026 13.98 15.67C14.2107 15.5374 14.3794 15.3186 14.4487 15.0616C14.5181 14.8047 14.4826 14.5307 14.35 14.3L13 11.93Z"
          fill="black"
        />
      </svg>
    ),
  },
];

function DeliveryWindow() {
  const { state } = useLocation();
  const [loading, setLoading] = useState<boolean>(false);
  const [addressName, setAddressName] = useState<string | undefined>('My home');
  const [saveAddressChecked, setSaveAddressChecked] = useState<Boolean>(true);
  const [deliveryTime, setDeliveryTime] = useState<{
    value: number;
    text: string;
    icon: JSX.Element;
  }>(deliveryTimes[0]);

  const {
    orderDetails,
    getOrderDetailsLocally,
    loading: integrationLoading,
    updateIntegrationId,
  } = useIntegrations();
  const { eventTracker } = useAnalytics('Address Verification');
  const navigate = useNavigate();

  useEffect(() => {
    getOrderDetailsLocally();
  }, []);

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: window.location.pathname,
      title: 'Delivery Window',
    });
  }, []);

  async function submit() {
    setLoading(true);
    try {
      const response = await updateIntegrationId(
        state.addressRequestJob.id as string,
        {
          type: state.addressRequestJob.deliveryDetails.addressType,
          countryCode: state.addressRequestJob.deliveryDetails.countryCode,
          addressFields: state.addressRequestJob.deliveryDetails.addressFields,
          geoLocation: state.addressRequestJob.deliveryDetails.geoLocation,
          deliveryWindows: [
            {
              startAt: deliveryTime.text.split(' - ')?.[0] as string,
              endAt: deliveryTime.text.split(' - ')?.[1] as string,
            },
          ],
        },
      );

      if (response.status === 200) {
        eventTracker({ action: 'delivery_window_submit', label: 'success' });
        // @ts-ignore
        navigate('/address-saved');
        mixpanel.track('Update Delivery Windows', {
          status: 'success',
          integrationId: state.addressRequestJob.id,
        });
      } else {
        // @ts-ignore
        toast.error(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        mixpanel.track('Update Delivery Windows', {
          status: 'failure',
          integrationId: state.addressRequestJob.id,
        });
      }
      eventTracker({
        action: 'save_address_checkbox',
        label: saveAddressChecked ? 'checked' : 'unchecked',
      });
    } catch (error) {
      setLoading(false);
      // @ts-ignore
      toast.error(error.response?.data?.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      eventTracker({ action: 'delivery_window_submit', label: 'failure' });
      mixpanel.track('Update Delivery Windows', {
        status: 'failure',
        integrationId: state.addressRequestJob.id,
      });
    }
    setLoading(false);
  }

  const getOptionLabel = (e: {
    value: number;
    icon: JSX.Element;
    text: string;
  }) => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {e.icon}
      <span style={{ marginLeft: 5 }}>{e.text}</span>
    </div>
  );

  return (
    <LoadingOverlay
      active={loading || integrationLoading}
      spinner
      text="Submitting your content...">
      <div className="create-address">
        <h1>Your order from {orderDetails?.orderSource}</h1>
        <Row>
          <Col>
            <p>
              Order <span>#{orderDetails?.orderNumber}</span>
            </p>
          </Col>
        </Row>
        <Form>
          <Form.Group className="mb-3" controlId="country">
            <Form.Label>Delivery window</Form.Label>
            <ReactSelect
              value={deliveryTime}
              onChange={(newValue) => {
                eventTracker({
                  action: 'delivery_window_interaction',
                  label: 'window',
                });
                setDeliveryTime(
                  newValue as {
                    value: number;
                    text: string;
                    icon: JSX.Element;
                  },
                );
              }}
              options={deliveryTimes}
              getOptionLabel={getOptionLabel as any}
            />
          </Form.Group>
          <Form.Check
            type="checkbox"
            defaultChecked
            id="Save my address for my next order"
            label="Save my address for my next order"
            onChange={(e) => {
              eventTracker({
                action: 'delivery_window_interaction',
                label: 'save_address_checkbox',
              });
              setSaveAddressChecked(e.target.checked);
            }}
          />
          <Form.Group className="mb-3" controlId="email">
            <Form.Label>Address Name</Form.Label>
            <Form.Control
              type="Text"
              defaultValue={addressName}
              placeholder='e.g. "My Home"'
              onChange={(e) => {
                eventTracker({
                  action: 'delivery_window_interaction',
                  label: 'address_name',
                });
                setAddressName(e.target.value);
              }}
            />
          </Form.Group>
          <div className="d-grid gap-2">
            <Button
              disabled={saveAddressChecked && !addressName}
              variant={
                saveAddressChecked && !addressName ? 'disabled' : 'primary'
              }
              size="lg"
              onClick={submit}>
              Save address
            </Button>
          </div>
        </Form>
        <ToastContainer />
      </div>
    </LoadingOverlay>
  );
}

export default DeliveryWindow;
