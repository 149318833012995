export const MAPBOX_TOKEN =
  'pk.eyJ1IjoiYW1lbC1kZXYiLCJhIjoiY2w5dHYzam1vMTM4czQ2bGZicTMxcGg4eCJ9.z3qgKLBdeoUTjHFRZAFAwg';

export const mapboxReverseGeocode = async (params: {
  longitude: number;
  latitude: number;
}) => {
  try {
    const response = await fetch(
      `https://api.mapbox.com/geocoding/v5/mapbox.places/${params.longitude},${params.latitude}.json?access_token=${MAPBOX_TOKEN}`,
    );
    const data = await response.json();

    let city;
    let state;
    let governorate;
    let province;
    let countryCode;
    let postalCode;
    let country;

    if (data.features && data.features.length > 0) {
      const firstFeature = data.features[0];
      const countryContext = firstFeature.context.find(
        (context: { id: string }) => context.id.startsWith('country'),
      );
      if (countryContext) {
        countryCode = countryContext.short_code.toUpperCase();
        country = countryContext.text;
      }

      const stateContext = firstFeature.context.find(
        (context: { id: string }) => context.id.startsWith('region'),
      );
      if (stateContext) {
        state = stateContext.text;
      }

      const cityContext = firstFeature.context.find((context: { id: string }) =>
        context.id.startsWith('locality'),
      );
      if (cityContext) {
        city = cityContext.text;
      }

      const govContext = firstFeature.context.find((context: { id: string }) =>
        context.id.startsWith('region'),
      );
      if (govContext) {
        governorate = govContext.text;
      }

      const provContext = firstFeature.context.find((context: { id: string }) =>
        context.id.startsWith('region'),
      );
      if (provContext) {
        province = provContext.text;
      }

      const postalCodeContext = firstFeature.context.find(
        (context: { id: string }) => context.id.startsWith('postcode'),
      );
      if (postalCodeContext) {
        postalCode = postalCodeContext.text;
      }

      const streets = data.features.filter(
        (feature: { place_name: any }) => feature.place_name,
      );

      return {
        latitude: params.latitude,
        longitude: params.longitude,
        countryCode,
        state: state || '',
        governorate: governorate || '',
        province: province || '',
        city: city || state || '',
        postalCode,
        country,
        streets,
        addressText: firstFeature.place_name,
      };
    }

    const errorMessage = 'No Addresses Found';
    console.error(errorMessage);
    throw Error(errorMessage);
  } catch (error) {
    console.error('Error fetching address:', error);
    if (error instanceof Error) {
      throw new Error(error.message);
    } else {
      throw new Error(String(error));
    }
  }
};
