export const TN_NAMES: { [key: string]: any } = {
  Gafsa: {
    englishName: 'Gafsa',
    arabicName: 'قفصة',
    cities: {
      'El Mdhilla': { englishName: 'El Mdhilla', arabicName: 'مظيلة' },
      'Sidi Aich': { englishName: 'Sidi Aich', arabicName: 'سيدي عيش' },
      'Gafsa Nord': {
        englishName: 'Gafsa Nord',
        arabicName: 'قفصة الشمالية',
      },
      'El ksar': { englishName: 'El ksar', arabicName: 'القصر' },
      'El Guettar': { englishName: 'El Guettar', arabicName: 'القطار' },
      'Gafsa Sud': {
        englishName: 'Gafsa Sud',
        arabicName: 'قفصة الجنوبية',
      },
      Moulares: {
        englishName: 'Moulares',
        arabicName: 'أم العرائس',
      },
      Sned: { englishName: 'Sned', arabicName: 'الصناد' },
      Belkhir: { englishName: 'Belkhir', arabicName: 'بلخير' },
      Redeyef: { englishName: 'Redeyef', arabicName: 'الرديف' },
      Metlaoui: { englishName: 'Metlaoui', arabicName: 'متلوي' },
    },
  },
  Kasserine: {
    englishName: 'Kasserine',
    arabicName: 'القصرين',
    cities: {
      Jediliane: { englishName: 'Jediliane', arabicName: 'جدليان' },
      'El Ayoun': { englishName: 'El Ayoun', arabicName: 'العيون' },
      Thala: { englishName: 'Thala', arabicName: 'تالة' },
      Foussana: { englishName: 'Foussana', arabicName: 'فوسانة' },
      'Kasserine Sud': {
        englishName: 'Kasserine Sud',
        arabicName: 'قصرين الجنوبية',
      },
      'Kasserine Nord': {
        englishName: 'Kasserine Nord',
        arabicName: 'قصرين الشمالية',
      },
      Ezzouhour: { englishName: 'Ezzouhour', arabicName: 'الزهور' },
      Sbeitla: { englishName: 'Sbeitla', arabicName: 'سبيطلة' },
      'Hassi El Frid': {
        englishName: 'Hassi El Frid',
        arabicName: 'حاسي الفريد',
      },
      'Majel Bel Abbes': {
        englishName: 'Majel Bel Abbes',
        arabicName: 'ماجل بلعباس',
      },
      Feriana: { englishName: 'Feriana', arabicName: 'فريانة' },
      Haidra: { englishName: 'Haidra', arabicName: 'هيدرة' },
      Sbiba: { englishName: 'Sbiba', arabicName: 'سبيبة' },
    },
  },
  'Sidi Bouzid': {
    englishName: 'Sidi Bouzid',
    arabicName: 'سيدي بوزيد',
    cities: {
      'Ben Oun': {
        englishName: 'Ben Oun',
        arabicName: 'سيدي علي بن عون',
      },
      Regueb: { englishName: 'Regueb', arabicName: 'الرقاب' },
      Maknassy: { englishName: 'Maknassy', arabicName: 'المكناسي' },
      Mezzouna: { englishName: 'Mezzouna', arabicName: 'المزونة' },
      'Sidi Bouzid Est': {
        englishName: 'Sidi Bouzid Est',
        arabicName: 'سيدي بوزيد الشرقية',
      },
      'Ouled Haffouz': {
        englishName: 'Ouled Haffouz',
        arabicName: 'أولاد حفوز',
      },
      'Bir El Haffey': {
        englishName: 'Bir El Haffey',
        arabicName: 'بئر الحفي',
      },
      Cebbala: {
        englishName: 'Cebbala',
        arabicName: 'سبالة',
      },
      'Sidi Bouzid Ouest': {
        englishName: 'Sidi Bouzid Ouest',
        arabicName: 'سيدي بوزيد الغربية',
      },
      Jilma: { englishName: 'Jilma', arabicName: 'جلمة' },
      'Souk Jedid': { englishName: 'Souk Jedid', arabicName: 'السوق الجديد' },
      'Menzel Bouzaiene': {
        englishName: 'Menzel Bouzaiene',
        arabicName: 'منزل بوزيان',
      },
    },
  },
  Jendouba: {
    englishName: 'Jendouba',
    arabicName: 'جندوبة',
    cities: {
      'Bou Salem': { englishName: 'Bou Salem', arabicName: 'بوسالم' },
      Ghardimaou: { englishName: 'Ghardimaou', arabicName: 'غار الدماء' },
      Fernana: { englishName: 'Fernana', arabicName: 'فرنانة' },
      'Oued Mliz': { englishName: 'Oued Mliz', arabicName: 'وادي مليز' },
      'Ain Draham': { englishName: 'Ain Draham', arabicName: 'عين دراهم' },
      'Jendouba Nord': {
        englishName: 'Jendouba Nord',
        arabicName: 'جندوبة الشمالية',
      },
      Jendouba: { englishName: 'Jendouba', arabicName: 'جندوبة' },
      Tabarka: { englishName: 'Tabarka', arabicName: 'طبرقة' },
      'Balta Bou Aouene': {
        englishName: 'Balta Bou Aouene',
        arabicName: 'بالطة بوعوان',
      },
    },
  },
  Tozeur: {
    englishName: 'Tozeur',
    arabicName: 'توزر',
    cities: {
      Tameghza: { englishName: 'Tameghza', arabicName: 'تامغزة' },
      Hezoua: { englishName: 'Hezoua', arabicName: 'الحزوة' },
      Nefta: { englishName: 'Nefta', arabicName: 'نفطة' },
      Tozeur: { englishName: 'Tozeur', arabicName: 'توزر' },
      Degueche: { englishName: 'Degueche', arabicName: 'دقاش' },
    },
  },
  Kebili: {
    englishName: 'Kebili',
    arabicName: 'قبلي',
    cities: {
      'EL Faouar': { englishName: 'EL Faouar', arabicName: 'فوار' },
      'Douz Nord': { englishName: 'Douz Nord', arabicName: 'دوز الشمالية' },
      'Souk El Ahad': {
        englishName: 'Souk El Ahad',
        arabicName: 'سوق الأحد',
      },
      'Kebeli Sud': {
        englishName: 'Kebeli Sud',
        arabicName: 'قبلي الجنوبية',
      },
      'Douz Sud': { englishName: 'Douz Sud', arabicName: 'دوز الجنوبية' },
      'Kebeli Nord': {
        englishName: 'Kebeli Nord',
        arabicName: 'قبلي الشمالية',
      },
    },
  },
  Kairouan: {
    englishName: 'Kairouan',
    arabicName: 'القيروان',
    cities: {
      Cherarda: { englishName: 'Cherarda', arabicName: 'الشراردة' },
      Oueslatia: {
        englishName: 'Oueslatia',
        arabicName: 'الوسلاتية',
      },
      Sbikha: { englishName: 'Sbikha', arabicName: 'السبيخة' },
      'Bou Hajla': { englishName: 'Bou Hajla', arabicName: 'بوحجلة' },
      'Kairouan Sud': {
        englishName: 'Kairouan Sud',
        arabicName: 'القيروان الجنوبية',
      },
      'Kairouan Nord': {
        englishName: 'Kairouan Nord',
        arabicName: 'القيروان الشمالية',
      },
      Haffouz: { englishName: 'Haffouz', arabicName: 'حفوز' },
      Nasrallah: { englishName: 'Nasrallah', arabicName: 'نصر الله' },
      'Hajeb El Ayoun': {
        englishName: 'Hajeb El Ayoun',
        arabicName: 'حاجب العيون',
      },
      Chebika: { englishName: 'Chebika', arabicName: 'الشبيكة' },
      'El Ala': { englishName: 'El Ala', arabicName: 'العلا' },
    },
  },
  Gabes: {
    englishName: 'Gabes',
    arabicName: 'قابس',
    cities: {
      'Nouvelle Matmata': {
        englishName: 'Nouvelle Matmata',
        arabicName: 'مطماطة الجديدة',
      },
      Matmata: { englishName: 'Matmata', arabicName: 'مطماطة' },
      'El Metouia': { englishName: 'El Metouia', arabicName: 'المطوية' },
      'Menzel Habib': {
        englishName: 'Menzel Habib',
        arabicName: 'منزل الحبيب',
      },
      'Gabes Ouest': {
        englishName: 'Gabes Ouest',
        arabicName: 'قابس الغربية',
      },
      'El Hamma': { englishName: 'El Hamma', arabicName: 'الحامة' },
      Mareth: { englishName: 'Mareth', arabicName: 'مارث' },
      'Gabes Sud': {
        englishName: 'Gabes Sud',
        arabicName: 'قابس الجنوبية',
      },
      Ghannouche: { englishName: 'Ghannouche', arabicName: 'غنوش' },
      'Gabes Medina': {
        englishName: 'Gabes Medina',
        arabicName: 'قابس المدينة',
      },
    },
  },
  Tunis: {
    englishName: 'Tunis',
    arabicName: 'تونس',
    cities: {
      'Bab Souika': {
        englishName: 'Bab Souika',
        arabicName: 'باب السويقة',
      },
      'El Kram': { englishName: 'El Kram', arabicName: 'الكرم' },
      'Sidi Hassine': {
        englishName: 'Sidi Hassine',
        arabicName: 'سيدي حسين',
      },
      'El Hrairia': { englishName: 'El Hrairia', arabicName: 'الحرايرية' },
      Ettahrir: { englishName: 'Ettahrir', arabicName: 'التحرير' },
      'Le Bardo': { englishName: 'Le Bardo', arabicName: 'الباردو' },
      'El Omrane Superieur': {
        englishName: 'El Omrane Superieur',
        arabicName: 'العمران الأعلى',
      },
      'El Kabbaria': { englishName: 'El Kabbaria', arabicName: 'القبارية' },
      Essijoumi: { englishName: 'Essijoumi', arabicName: 'السيجومي' },
      'El Menzah': { englishName: 'El Menzah', arabicName: 'المنزه' },
      'El Omrane': { englishName: 'El Omrane', arabicName: 'العمران' },
      'La Goulette': { englishName: 'La Goulette', arabicName: 'لا ڨوليط' },
      'Bab Bhar': { englishName: 'Bab Bhar', arabicName: 'باب بحر' },
      'La Medina': { englishName: 'La Medina', arabicName: 'المدينة' },
      'El Ourdia': { englishName: 'El Ourdia', arabicName: 'العورضية' },
      'Sidi El Bechir': {
        englishName: 'Sidi El Bechir',
        arabicName: 'سيدي البشير',
      },
      'Jebel Jelloud': {
        englishName: 'Jebel Jelloud',
        arabicName: 'جبل جلود',
      },
      'Cite El Khadra': {
        englishName: 'Cite El Khadra',
        arabicName: 'السيتي الخضراء',
      },
      'La Marsa': { englishName: 'La Marsa', arabicName: 'المرسى' },
      Carthage: { englishName: 'Carthage', arabicName: 'قرطاج' },
      'La Soukra': { englishName: 'La Soukra', arabicName: 'السكرة' },
    },
  },
  Tataouine: {
    englishName: 'Tataouine',
    arabicName: 'تطاوين',
    cities: {
      'Tataouine Sud': {
        englishName: 'Tataouine Sud',
        arabicName: 'تطاوين الجنوبية',
      },
      Ghomrassen: { englishName: 'Ghomrassen', arabicName: 'غمراسن' },
      'Tataouine Nord': {
        englishName: 'Tataouine Nord',
        arabicName: 'تطاوين الشمالية',
      },
      Smar: { englishName: 'Smar', arabicName: 'سمار' },
      'Bir Lahmar': {
        englishName: 'Bir Lahmar',
        arabicName: 'بئر الأحمر',
      },
      Dhehiba: { englishName: 'Dhehiba', arabicName: 'الدهيبة' },
      Remada: { englishName: 'Remada', arabicName: 'الرمادة' },
    },
  },
  Mednine: {
    englishName: 'Mednine',
    arabicName: 'مدنين',
    cities: {
      'Beni Khedache': {
        englishName: 'Beni Khedache',
        arabicName: 'بني خداش',
      },
      Zarzis: { englishName: 'Zarzis', arabicName: 'جرجيس' },
      'Ben Guerdane': {
        englishName: 'Ben Guerdane',
        arabicName: 'بن قردان',
      },
      'Houmet Essouk': {
        englishName: 'Houmet Essouk',
        arabicName: 'حومة السوق',
      },
      Midoun: {
        englishName: 'Midoun',
        arabicName: 'ميدون',
      },
      'Medenine Sud': {
        englishName: 'Medenine Sud',
        arabicName: 'مدنين الجنوبية',
      },
      'Sidi Makhlouf': {
        englishName: 'Sidi Makhlouf',
        arabicName: 'سيدي مخلوف',
      },
      Ajim: {
        englishName: 'Ajim',
        arabicName: 'أجيم',
      },
      'Medenine Nord': {
        englishName: 'Medenine Nord',
        arabicName: 'مدنين الشمالية',
      },
    },
  },
  'Ben Arous': {
    englishName: 'Ben Arous',
    arabicName: 'بن عروس',
    cities: {
      'Hammam Chatt': {
        englishName: 'Hammam Chatt',
        arabicName: 'حمام الشط',
      },
      Fouchana: { englishName: 'Fouchana', arabicName: 'فوشانة' },
      Mohamadia: { englishName: 'Mohamadia', arabicName: 'المحمدية' },
      Mornag: { englishName: 'Mornag', arabicName: 'مرناق' },
      Megrine: { englishName: 'Megrine', arabicName: 'مقرين' },
      'Nouvelle Medina': {
        englishName: 'Nouvelle Medina',
        arabicName: 'المدينة الجديدة',
      },
      'El Mourouj': { englishName: 'El Mourouj', arabicName: 'المروج' },
      'Ben Arous': { englishName: 'Ben Arous', arabicName: 'بن عروس' },
      'Hammam Lif': { englishName: 'Hammam Lif', arabicName: 'حمام الأنف' },
      Ezzahra: { englishName: 'Ezzahra', arabicName: 'الزهراء' },
      'Bou Mhel El Bassatine': {
        englishName: 'Bou Mhel El Bassatine',
        arabicName: 'بومهل البساتين',
      },
      Rades: { englishName: 'Rades', arabicName: 'رادس' },
    },
  },
  Mahdia: {
    englishName: 'Mahdia',
    arabicName: 'المهدية',
    cities: {
      'El Jem': { englishName: 'El Jem', arabicName: 'الجم' },
      'Bou Merdes': { englishName: 'Bou Merdes', arabicName: 'بو مرادس' },
      Mahdia: { englishName: 'Mahdia', arabicName: 'المهدية' },
      Melloulech: { englishName: 'Melloulech', arabicName: 'ملولش' },
      'Ouled Chamakh': {
        englishName: 'Ouled Chamakh',
        arabicName: 'أولاد شامخ',
      },
      Chorbane: { englishName: 'Chorbane', arabicName: 'شربان' },
      Hbira: { englishName: 'Hbira', arabicName: 'الحبيرة' },
      Souassi: { englishName: 'Souassi', arabicName: 'الصواسي' },
      'La Chebba': { englishName: 'La Chebba', arabicName: 'الشابة' },
      'Ksour Essaf': {
        englishName: 'Ksour Essaf',
        arabicName: 'قصور الساف',
      },
      'Sidi Alouene': {
        englishName: 'Sidi Alouene',
        arabicName: 'سيدي علوان',
      },
    },
  },
  Nabeul: {
    englishName: 'Nabeul',
    arabicName: 'نابل',
    cities: {
      'Menzel Bouzelfa': {
        englishName: 'Menzel Bouzelfa',
        arabicName: 'منزل بوزلفة',
      },
      'Bou Argoub': { englishName: 'Bou Argoub', arabicName: 'بوعرقوب' },
      Hammamet: { englishName: 'Hammamet', arabicName: 'الحمامات' },
      Nabeul: { englishName: 'Nabeul', arabicName: 'نابل' },
      'Dar Chaabane El Fehri': {
        englishName: 'Dar Chaabane El Fehri',
        arabicName: 'دار شعبان الفهري',
      },
      'Beni Khiar': { englishName: 'Beni Khiar', arabicName: 'بني خيار' },
      Korba: { englishName: 'Korba', arabicName: 'قربة' },
      'EL Mida': { englishName: 'EL Mida', arabicName: 'الميدة' },
      'Menzel Temime': {
        englishName: 'Menzel Temime',
        arabicName: 'منزل تميم',
      },
      'El Houaria': { englishName: 'El Houaria', arabicName: 'الهوارية' },
      Kelibia: { englishName: 'Kelibia', arabicName: 'قليبية' },
      Grombelia: { englishName: 'Grombelia', arabicName: 'قرمبالية' },
      Soliman: { englishName: 'Soliman', arabicName: 'سليمان' },
      Takelsa: { englishName: 'Takelsa', arabicName: 'تاكلسة' },
      'Beni Khalled': { englishName: 'Beni Khalled', arabicName: 'بني خالد' },
      'Hammam El Ghezaz': {
        englishName: 'Hammam El Ghezaz',
        arabicName: 'حمام القزاز',
      },
    },
  },
  Beja: {
    englishName: 'Beja',
    arabicName: 'باجة',
    cities: {
      Amdoun: { englishName: 'Amdoun', arabicName: 'أم العرائس' },
      Nefza: { englishName: 'Nefza', arabicName: 'نفزة' },
      Goubellat: { englishName: 'Goubellat', arabicName: 'قبلاط' },
      Testour: { englishName: 'Testour', arabicName: 'تستور' },
      'Mejez El Bab': {
        englishName: 'Mejez El Bab',
        arabicName: 'مجاز الباب',
      },
      Teboursouk: { englishName: 'Teboursouk', arabicName: 'تبرسق' },
      'Beja Sud': { englishName: 'Beja Sud', arabicName: 'باجة الجنوبية' },
      'Beja Nord': {
        englishName: 'Beja Nord',
        arabicName: 'باجة الشمالية',
      },
      Thibar: { englishName: 'Thibar', arabicName: 'تيبار' },
    },
  },
  Ariana: {
    englishName: 'Ariana',
    arabicName: 'أريانة',
    cities: {
      'Sidi Thabet': {
        englishName: 'Sidi Thabet',
        arabicName: 'سيدي ثابت',
      },
      Mnihla: { englishName: 'Mnihla', arabicName: 'المنيهلة' },
      'Kalaat Landalous': {
        englishName: 'Kalaat Landalous',
        arabicName: 'قلعة الأندلس',
      },
      Ettadhamen: {
        englishName: 'Ettadhamen',
        arabicName: 'التضامن',
      },
      'Ariana Ville': {
        englishName: 'Ariana Ville',
        arabicName: 'أريانة المدينة',
      },
      'La Soukra': { englishName: 'La Soukra', arabicName: 'السكرة' },
      Raoued: { englishName: 'Raoued', arabicName: 'رواد' },
    },
  },
  Mannouba: {
    englishName: 'Mannouba',
    arabicName: 'منوبة',
    cities: {
      'Oued Elil': { englishName: 'Oued Elil', arabicName: 'وادي الليل' },
      Mannouba: { englishName: 'Mannouba', arabicName: 'منوبة' },
      'Douar Hicher': {
        englishName: 'Douar Hicher',
        arabicName: 'دوار هيشر',
      },
      'Borj El Amri': { englishName: 'Borj El Amri', arabicName: 'برج عمري' },
      Mornaguia: { englishName: 'Mornaguia', arabicName: 'مرناقية' },
      'El Battan': { englishName: 'El Battan', arabicName: 'البطان' },
      Jedeida: { englishName: 'Jedeida', arabicName: 'الجديدة' },
      Tebourba: { englishName: 'Tebourba', arabicName: 'طبربة' },
    },
  },
  Zaghouan: {
    englishName: 'Zaghouan',
    arabicName: 'زغوان',
    cities: {
      'Bir Mchergua': {
        englishName: 'Bir Mchergua',
        arabicName: 'بئر المشارقة',
      },
      'El Fahs': { englishName: 'El Fahs', arabicName: 'الفحص' },
      Ennadhour: { englishName: 'Ennadhour', arabicName: 'الناظور' },
      Saouef: { englishName: 'Saouef', arabicName: 'صواف' },
      'Hammam Zriba': { englishName: 'Hammam Zriba', arabicName: 'الزريبة' },
      Zaghouan: { englishName: 'Zaghouan', arabicName: 'زغوان' },
    },
  },
  Siliana: {
    englishName: 'Siliana',
    arabicName: 'سليانة',
    cities: {
      Bargou: { englishName: 'Bargou', arabicName: 'برقو' },
      'Bou Arada': { englishName: 'Bou Arada', arabicName: 'بوعرادة' },
      'El Aroussa': { englishName: 'El Aroussa', arabicName: 'العروسة' },
      'Siliana Sud': {
        englishName: 'Siliana Sud',
        arabicName: 'سليانة الجنوبية',
      },
      Gaafour: { englishName: 'Gaafour', arabicName: 'قعفور' },
      'Siliana Nord': {
        englishName: 'Siliana Nord',
        arabicName: 'سليانة الشمالية',
      },
      'Le Krib': { englishName: 'Le Krib', arabicName: 'الكريب' },
      'Sidi Bou Rouis': { englishName: 'Sidi Bou Rouis', arabicName: 'برويس' },
      Rohia: { englishName: 'Rohia', arabicName: 'الروحية' },
      Makthar: { englishName: 'Makthar', arabicName: 'مكثر' },
      Kesra: { englishName: 'Kesra', arabicName: 'كسرى' },
    },
  },
  Kef: {
    englishName: 'Kef',
    arabicName: 'الكاف',
    cities: {
      'Le Sers': { englishName: 'Le Sers', arabicName: 'السرس' },
      'El Ksour': { englishName: 'El Ksour', arabicName: 'القصور' },
      'Sakiet Sidi Youssef': {
        englishName: 'Sakiet Sidi Youssef',
        arabicName: 'ساقية سيدي يوسف',
      },
      Tajerouine: { englishName: 'Tajerouine', arabicName: 'تاجروين' },
      Nebeur: { englishName: 'Nebeur', arabicName: 'النبر' },
      'Kef Ouest': {
        englishName: 'Kef Ouest',
        arabicName: 'الكاف الغربية',
      },
      Dahmani: { englishName: 'Dahmani', arabicName: 'الدهماني' },
      'Kef Est': { englishName: 'Kef Est', arabicName: 'الكاف الشرقية' },
      Jerissa: { englishName: 'Jerissa', arabicName: 'الجريصة' },
      'Kalla El Khasba': {
        englishName: 'Kalla El Khasba',
        arabicName: 'قلعة خصبة',
      },
      'Kallat Sinane': {
        englishName: 'Kallat Sinane',
        arabicName: 'قلعة السنان',
      },
    },
  },
  Sousse: {
    englishName: 'Sousse',
    arabicName: 'سوسة',
    cities: {
      'Sousse Ville': {
        englishName: 'Sousse Ville',
        arabicName: 'المدينة العتيقة بسوسة',
      },
      'Hammam Sousse': {
        englishName: 'Hammam Sousse',
        arabicName: 'حمام سوسة',
      },
      'Sousse Jaouhara': {
        englishName: 'Sousse Jaouhara',
        arabicName: 'سوسة الجوهرة',
      },
      Akouda: { englishName: 'Akouda', arabicName: 'عكودة' },
      'Kalaa Essghira': {
        englishName: 'Kalaa Essghira',
        arabicName: 'القلعة الصغيرة',
      },
      Msaken: { englishName: 'Msaken', arabicName: 'مساكن' },
      'Kalaa El Kebira': {
        englishName: 'Kalaa El Kebira',
        arabicName: 'القلعة الكبرى',
      },
      'Sid Bou Ali': {
        englishName: 'Sid Bou Ali',
        arabicName: 'سيدي بوعلي',
      },
      'Sidi El Heni': {
        englishName: 'Sidi El Heni',
        arabicName: 'سيدي الهاني',
      },
      Kondar: { englishName: 'Kondar', arabicName: 'كندار' },
      Enfidha: { englishName: 'Enfidha', arabicName: 'النفيضة' },
      'Bou Ficha': { englishName: 'Bou Ficha', arabicName: 'بوفيشة' },
      Hergla: { englishName: 'Hergla', arabicName: 'هرقلة' },
      Sousse: {
        englishName: 'Sousse',
        arabicName: 'سوسة',
      },
      'Sousse Riadh': {
        englishName: 'Sousse Riadh',
        arabicName: 'سوسة الرياض',
      },
    },
  },
  Sfax: {
    englishName: 'Sfax',
    arabicName: 'صفاقس',
    cities: {
      'Menzel Chaker': {
        englishName: 'Menzel Chaker',
        arabicName: 'منزل شاكر',
      },
      Agareb: { englishName: 'Agareb', arabicName: 'العقارب' },
      Mahras: { englishName: 'Mahras', arabicName: 'محرس' },
      Ghraiba: { englishName: 'Ghraiba', arabicName: 'الغرايبة' },
      'Bir Ali Ben khelifa': {
        englishName: 'Bir Ali Ben khelifa',
        arabicName: 'بئر علي بن خليفة',
      },
      Esskhira: { englishName: 'Esskhira', arabicName: 'السخيرة' },
      Kerkenah: { englishName: 'Kerkenah', arabicName: 'قرقنة' },
      'El Hencha': { englishName: 'El Hencha', arabicName: 'الحنشة' },
      'Sfax Sud': { englishName: 'Sfax Sud', arabicName: 'صفاقس الجنوبية' },
      'Sakiet Ezzit': {
        englishName: 'Sakiet Ezzit',
        arabicName: 'ساقية الزيت',
      },
      'Sfax Ouest': {
        englishName: 'Sfax Ouest',
        arabicName: 'صفاقس الغربية',
      },
      'Sfax Ville': {
        englishName: 'Sfax Ville',
        arabicName: 'صفاقس المدينة',
      },
      'EL Amra': { englishName: 'EL Amra', arabicName: 'العامرة' },
      'Sakiet Eddaier': {
        englishName: 'Sakiet Eddaier',
        arabicName: 'ساقية الدائر',
      },
      Jebeniana: { englishName: 'Jebeniana', arabicName: 'جبنيانة' },
    },
  },
  Monastir: {
    englishName: 'Monastir',
    arabicName: 'المنستير',
    cities: {
      Bekalta: { englishName: 'Bekalta', arabicName: 'البقالطة' },
      'Sayada Lamta Bou Hajar': {
        englishName: 'Sayada Lamta Bou Hajar',
        arabicName: 'صيادة لمطة بوحجر',
      },
      Moknine: { englishName: 'Moknine', arabicName: 'المكنين' },
      'Ksibet El Mediouni': {
        englishName: 'Ksibet El Mediouni',
        arabicName: 'القصيبة المديوني',
      },
      'Beni Hassen': { englishName: 'Beni Hassen', arabicName: 'بني حسان' },
      Bembla: { englishName: 'Bembla', arabicName: 'بنبلة' },
      Jemmal: { englishName: 'Jemmal', arabicName: 'الجمّال' },
      Ouerdanine: { englishName: 'Ouerdanine', arabicName: 'وردانين' },
      Zeramdine: { englishName: 'Zeramdine', arabicName: 'زرمدين' },
      Sahline: { englishName: 'Sahline', arabicName: 'الساحلين' },
      Monastir: { englishName: 'Monastir', arabicName: 'المنستير' },
      'Ksar Helal': { englishName: 'Ksar Helal', arabicName: 'قصر هلال' },
      Teboulba: { englishName: 'Teboulba', arabicName: 'طبلبة' },
    },
  },
  Bizerte: {
    englishName: 'Bizerte',
    arabicName: 'بنزرت',
    cities: {
      'Bizerte Nord': {
        englishName: 'Bizerte Nord',
        arabicName: 'بنزرت الشمالية',
      },
      Jarzouna: { englishName: 'Jarzouna', arabicName: 'زرزونة' },
      'Bizerte Sud': {
        englishName: 'Bizerte Sud',
        arabicName: 'بنزرت الجنوبية',
      },
      'Menzel Bourguiba': {
        englishName: 'Menzel Bourguiba',
        arabicName: 'منزل بورقيبة',
      },
      Tinja: { englishName: 'Tinja', arabicName: 'تنجة' },
      'Menzel Jemil': {
        englishName: 'Menzel Jemil',
        arabicName: 'منزل جميل',
      },
      'El Alia': { englishName: 'El Alia', arabicName: 'العالية' },
      Ghezala: { englishName: 'Ghezala', arabicName: 'غزالة' },
      Sejnane: { englishName: 'Sejnane', arabicName: 'سجنان' },
      Mateur: { englishName: 'Mateur', arabicName: 'ماطر' },
      Utique: { englishName: 'Utique', arabicName: 'أوتيك' },
      Joumine: { englishName: 'Joumine', arabicName: 'جومين' },
      'Ras Jebel': { englishName: 'Ras Jebel', arabicName: 'رأس الجبل' },
      'Ghar El Melh': {
        englishName: 'Ghar El Melh',
        arabicName: 'غار الملح',
      },
    },
  },
};
